import Tree, { SelectionChangeResponse } from '@rio-cloud/rio-uikit/components/assetTree/Tree';
import TreeCategory from '@rio-cloud/rio-uikit/components/assetTree/TreeCategory';
import { useDispatch, useSelector } from 'react-redux';
import { addQuestions, iconToService, selectAllFAQs } from '../../../../store/faq/faqSlice';
import { getMockFAQs } from '../../../../../mocks/mock.faqs';
import { useIntl } from 'react-intl';
import getDatesToTranslations from '../../../../utils/getDatesToTranslations/getDatesToTranslations';
import { Service, setCurrentService } from '../../../../store/service/serviceSlice';
import { addMessage } from '../../../../store/chat/chat.slice';
import {
  addLoadingThreadId,
  removeLoadingThreadId,
  selectActiveThreadId,
  selectIsThreadLoading,
  updateThread,
} from '../../../../store/thread/threadSlice';
import { useSendMessageMutation } from '../../../../services/chatApi/chatApi';
import { getLocale } from '../../../../configuration/lang/langSlice';
import styled from 'styled-components';
import { getUserId } from '../../../../utils/getUserId';
import { getUserProfile } from '../../../../configuration/login/loginSlice';

const FrequentlyAskedQuestionsList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentThreadId = useSelector(selectActiveThreadId);
  const isThreadsLoading = useSelector(selectIsThreadLoading(currentThreadId));
  const currentLanguage = useSelector(getLocale);
  const allFAQs = useSelector(selectAllFAQs);
  const dates = getDatesToTranslations();
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const [sendMessage, { isLoading }] = useSendMessageMutation();
  const mappedFAQs = getMockFAQs().map((faq) => ({
    id: faq.id,
    name: intl.formatMessage({ id: faq.translation }, dates),
    type: faq.icon,
  }));

  if (allFAQs.length === 0) {
    dispatch(addQuestions(mappedFAQs));
  }

  const handleSelection = async ({ items }: SelectionChangeResponse) => {
    if (isLoading || isThreadsLoading) return;

    const question = mappedFAQs.find((q) => q.id === items[0]);
    if (!question) return;

    try {
      if (question.type) {
        dispatch(setCurrentService(iconToService[question.type] as Service));
      }
      await dispatch(updateThread({ firstMessage: question.name ?? '', threadId: currentThreadId }));

      const message = {
        content: question.name,
        role: 'human',
        type: 'text',
        threadId: currentThreadId,
        timestamp: new Date().toISOString(),
        service: iconToService[question.type ?? 'perform'],
        isOnboardingQuestion: false,
      };

      const requestMessage = {
        service: iconToService[question.type ?? 'perform'],
        question: question.name,
        language: currentLanguage === 'de-DE' ? 'Deutsch' : 'English',
        threadId: currentThreadId,
        meta: { isOnboarding: false, currentLanguage },
        ...(iconToService[question.type ?? 'perform'] === 'training' && { use_temp_db: false }),
        userId,
      };

      dispatch(addMessage(message));

      dispatch(addLoadingThreadId(currentThreadId));
      await sendMessage(requestMessage).unwrap();
    } catch (err) {
      console.error('Failed to send the message: ', err);
    } finally {
      dispatch(removeLoadingThreadId(currentThreadId));
    }
  };

  return (
    <TreeCategory
      key="faqs-tree-cateory"
      id="faqs-tree-category"
      label="Question"
      icon="rioglyph rioglyph-question-sign"
      hasSelection={false}
    >
      <StyledTree
        groups={[]}
        items={allFAQs}
        expandedGroups={[]}
        selectedGroups={[]}
        selectedItems={[]}
        searchPlaceholder={intl.formatMessage({ id: 'chatPage.faqList.placeholder' })}
        hasMultiselect={false}
        onSelectionChange={handleSelection}
        isLoading={isLoading || isThreadsLoading}
      />
    </TreeCategory>
  );
};

export default FrequentlyAskedQuestionsList;

const StyledTree = styled(Tree)<{ isLoading: boolean }>`
  & > .TreeRoot .TreeNodeContainer .TreeNode .TreeLabel.TreeLabelName,
  .TreeRoot .TreeNodeContainer .TreeLeaf .TreeLabel.TreeLabelName {
    cursor: ${(props) => (props.isLoading ? 'not-allowed' : 'pointer')} !important;
  }
`;
