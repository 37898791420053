import { MockMessages } from "../src/store/onboarding/onboardingSlice";

export const mockOnboardingMessageEN: MockMessages = {
      'step-1': {
            question: "Which drivers need support due to an overall rating below 70 on average in the last month and list their ratings?",
            answer: 
            "The following drivers need support due to an overall rating below **70** on average over the last month:\n\n" +
            "| Driver               | Average Total Rating              |\n" +
            "|----------------------|-----------------------------------|\n" +
            "| Max Mustermann       | **25.0**                          |\n" +
            "| Jan Kowalski         | **26.0**                          |\n" +
            "| John Doe             | **26.0**                          |\n" +
            "| Morten Menigmand     | **27.0**                          |\n" +
            "| Jean Dupont          | **44.0**                          |\n" +
            "| Mario Rossi          | **44.0**                          |\n" +
            "| Jan Janssen          | **45.0**                          |\n" +
            "| Jane Doe             | **48.0**                          |\n\n" +
            "These drivers should be provided with additional support and training to improve their overall performance and reach the target average total rating of **70** or above.\n\n"
        },
      'step-2': {
            question: "What is the reason for the low rating of Max Mustermann?",
            answer:  "The reason for the low rating of **Max Mustermann** in the last month appears to be:\n\n" +
            "- Low average braking pedal rating of **15.0**\n\n" +
            "The low braking pedal rating suggests that Max Mustermann had issues with smooth and controlled braking during this time period. These factors likely contributed to the overall low total rating of **25.0** for Max Mustermann in the last month.\n\n"
      },
      'step-3': {
            question: "How is the brake rating put together?",
            answer:  "The **braking rating** is put together as follows:\n\n" +
            "1. Ratio of Wear-Free Brake to Service Brake:\n" +
            "    - The rating is based on the ratio of the braked distance using the wear-free brake (e.g. retarder, engine brake) compared to the service brake.\n" +
            "    - A **ratio of 5:1 or higher** for the wear-free brake results in a **100% braking rating**.\n" +
            "    - A **ratio of 0.5:1 or lower** for the wear-free brake results in a **0% braking rating**.\n\n" +
            "2. Percentage of Distance Braked with Service Brake:\n" +
            "    - The rating also considers the percentage of the total driving distance that is braked using the service brake.\n" +
            "    - **Less than 1%** of the distance braked with the service brake results in a **100% braking rating**.\n" +
            "    - **More than 5.5%** of the distance braked with the service brake results in a **0% braking rating**.\n\n" +
            "3. Overall Braking Rating:\n" +
            "    - The overall braking rating is the calculated average of the sub-ratings, which include coasting mode, cruise control, accelerator, brakes and overspeed.\n" +
            "    - The higher the overall value, the better the braking rating.\n\n" +
            "In summary, the **braking rating** is a composite metric that considers the relative usage of the wear-free brake versus the service brake, as well as the percentage of the total distance braked using the service brake. Achieving a high braking rating requires **minimizing** the use of the service brake and **maximizing** the use of the wear-free braking systems.\n\n" +
            "---\n\n" +
            "Based on the Following Documents:\n\n" +
            "1. [Total_Rating - Page 3 - Part 2](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)\n" +
            "2. [Manual - Page 450 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=450)\n" +
            "3. [Manual - Page 71 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=71)\n" +
            "4. [Logging_csv - Page 15 - Part 7](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n" +
            "5. [Logging_csv - Page 16 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "6. [Logging_csv - Page 16 - Part 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "7. [Logging_csv - Page 15 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n" +
            "8. [Manual - Page 99 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=99)\n" +
            "9. [Logging_csv - Page 16 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "10. [Total_Rating - Page 3 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)"
      },
      'step-4': {
            question: "What can my driver Max Mustermann who has a low brake pedal rating do to improve?",
            answer:  "Here are some suggestions for **Max Mustermann** and other drivers with a low brake pedal rating to improve:\n\n" +
            "1. Increase Use of Wear-Free Brakes:\n" +
            "    - A high ratio of braking distance using the wear-free brakes (e.g. retarder, engine brake) compared to the service brake is key for a good braking rating.\n" +
            "    - Drivers should be encouraged to utilize the wear-free braking systems as much as possible, rather than relying heavily on the service brake.\n\n" +
            "2. Reduce Service Brake Usage:\n" +
            "    - The braking rating penalizes drivers who use the service brake for more than 1% of the total driving distance.\n" +
            "    - Drivers should aim to minimize their use of the service brake by anticipating stops, coasting, and leveraging the wear-free braking systems.\n\n" +
            "3. Smooth, Controlled Braking:\n" +
            "    - Progressive braking is preferred over abrupt use of the service brake.\n" +
            "    - Drivers should practice smooth, controlled braking techniques to reduce wear on the braking components and improve the braking rating.\n\n" +
            "4. Utilize Assistive Technologies:\n" +
            "    - The overall braking rating is the calculated average of the sub-ratings, which include coasting mode, cruise control, accelerator, brakes and overspeed.\n" +
            "    - Encouraging the use of these technologies can indirectly improve the braking rating.\n\n" +
            "5. Anticipatory Driving:\n" +
            "    - By driving in a more anticipatory manner and releasing the accelerator pedal early, drivers can minimize the need to use the service brake.\n" +
            "    - This **coasting** behavior helps improve the braking rating.\n\n" +
            "The key for drivers is to focus on maximizing the use of wear-free braking systems, minimizing service brake usage, and adopting smooth, controlled braking techniques. Proper training and the utilization of available vehicle technologies can help drivers achieve a better braking rating.\n\n" +
            "---\n\n" +
            "Based on the Following Documents:\n\n" +
            "1. [Manual - Page 450 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=450)\n" +
            "2. [Manual - Page 71 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=71)\n" +
            "3. [Manual - Page 99 - Part 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=99)\n" +
            "4. [Logging_csv - Page 13 - Part 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=13)\n" +
            "5. [Total_Rating - Page 5 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=5)\n" +
            "6. [Total_Rating - Page 2 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=2)\n" +
            "7. [Logging_csv - Page 16 - Part 5](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "8. [Total_Rating - Page 3 - Part 2](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)\n" +
            "9. [Total_Rating - Page 7 - Part 2](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=7)\n" +
            "10. [Total_Rating - Page 3 - Part 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)"
      }
      };