import ApplicationLayout from '@rio-cloud/rio-uikit/components/applicationLayout/ApplicationLayout';
import TreeCategory from '@rio-cloud/rio-uikit/components/assetTree/TreeCategory';
import { useState } from 'react';
import ChatHistoryList from '../ChatHistoryList/ChatHistoryList';
import AssetTree from '@rio-cloud/rio-uikit/components/assetTree/AssetTree';
import FavoritesThreadsList from '../FavoritesThreadsList/FavoritesThreadsList';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsSidebarOpen, toggleSidebar } from '../../../../store/sidebar/sidebarSlice';
import FrequentlyAskedQuestionsList from '../FrequentlyAskedQuestionsList/FrequentlyAskedQuestionsList';

const sidebarItems = ['history', 'favorites', 'faq'] as const;
type SidebarItem = (typeof sidebarItems)[number];

type VariantConfig = {
  [key in SidebarItem]: {
    translation: string;
    icon: string;
    component: React.ReactNode;
  };
};

export const variantConfig: VariantConfig = {
  history: {
    translation: 'chatPage.sidebar.history.label',
    icon: 'rioglyph-pencil-square',
    component: <ChatHistoryList />,
  },
  favorites: {
    translation: 'chatPage.sidebar.favorites.label',
    icon: 'rioglyph-star',
    component: <FavoritesThreadsList />,
  },
  faq: {
    translation: 'chatPage.sidebar.faq.label',
    icon: 'rioglyph-question-sign',
    component: <FrequentlyAskedQuestionsList />,
  },
} as const;

const ChatAssetTree = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isTreeOpen = useSelector(selectIsSidebarOpen);
  const [currentCategoryId, setCurrentCategoryId] = useState<SidebarItem>('history');

  const handleToggleTree = (toggle: boolean) => dispatch(toggleSidebar());

  const handleOnCategoryChange = (categoryId: string) => {
    if (sidebarItems.includes(categoryId as SidebarItem)) {
      setCurrentCategoryId(categoryId as SidebarItem);
    }
  };

  return (
    <ApplicationLayout.Sidebar>
      <AssetTree
        minWidth={300}
        maxWidth={450}
        currentCategoryId={currentCategoryId}
        onCategoryChange={handleOnCategoryChange}
        isOpen={isTreeOpen}
        onToggleTree={handleToggleTree}
        useOffscreen
      >
        {sidebarItems.map((item) => (
          <TreeCategory
            key={`${item}-sidebar`}
            id={item}
            label={intl.formatMessage({ id: variantConfig[item].translation })}
            icon={variantConfig[item].icon}
          >
            {variantConfig[item].component}
          </TreeCategory>
        ))}
      </AssetTree>
    </ApplicationLayout.Sidebar>
  );
};

export default ChatAssetTree;
