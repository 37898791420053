import Button from '@rio-cloud/rio-uikit/Button';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';

type HowItWorksSectionProps = {
  handleReadMore: () => void;
};

const HowItWorksSection = ({ handleReadMore }: HowItWorksSectionProps) => {
  const intl = useIntl();
  const isDarkMode = useDarkMode();

  return (
    <Container isDarkMode={isDarkMode}>
      <StyledImage
        src="assets/images/how-it-works.svg"
        alt={intl.formatMessage({ id: 'getStartedPage.howItWorksSection.title' })}
      />
      <Content>
        <h2 className="h2 text-uppercase">
          <FormattedMessage id="getStartedPage.howItWorksSection.title" />
        </h2>
        <p>
          <FormattedMessage id="getStartedPage.howItWorksSection.description" />
        </p>
        <Button bsStyle={Button.PRIMARY} onClick={handleReadMore}>
          <FormattedMessage id="getStartedPage.howItWorksSection.readMore.label" />
        </Button>
      </Content>
    </Container>
  );
};

export default HowItWorksSection;

type ContainerProps = {
  isDarkMode: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  padding: 2rem;
  background: ${(props) => (!props.isDarkMode ? 'var(--gray-darkest)' : 'var(--color-white)')};
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isDarkMode ? 'var(--gray-darkest)' : 'var(--color-white)')};

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 1rem;
  }
`;

const StyledImage = styled.img`
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 80%;
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  padding: 3rem;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 6rem;
  }

  h2 {
    text-transform: uppercase;
  }

  p {
    margin-bottom: 2rem;
  }
`;
