import { getUserId } from '../../../../utils/getUserId';
import { useFetchFavoriteThreadsQuery } from '../../../../services/threadApi/threadApi';
import { useSelector } from 'react-redux';
import { getUserProfile } from '../../../../configuration/login/loginSlice';
import ThreadListItem from '../ThreadListItem/ThreadListItem';

const FavoritesThreadsList = () => {
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const { data } = useFetchFavoriteThreadsQuery({ userId });

  return (
    <div className="padding-20">
      <p className="text-bold padding-x-10">Favorites</p>
      {data?.data
        ? data.data.map((thread) => (
            <ThreadListItem
              key={`favorite-${thread.id}`}
              threadId={thread.id}
              firstMessage={thread.firstMessage}
              isFavorite
            />
          ))
        : null}
    </div>
  );
};

export default FavoritesThreadsList;
