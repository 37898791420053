import { categorizeThreadsByDate } from '../../utils/categorizeThreadsByDate/categorizeThreadsByDate';
import { createSelector, createSlice, nanoid, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { threadApi } from '../../services/threadApi/threadApi';
import { Response } from '../../utils/types';

export type ChatThread = {
  id: string;
  firstMessage: string;
  timestamp: string;
  isActive: boolean;
};

type ThreadsState = {
  threads: ChatThread[];
  loadingThreadIds: string[];
};

const createTodayThread = (): ChatThread => {
  const today = new Date().toISOString();
  return {
    id: nanoid(),
    firstMessage: '',
    timestamp: today,
    isActive: true,
  };
};

const initialState: ThreadsState = {
  threads: [createTodayThread()],
  loadingThreadIds: [],
};

const threadSlice = createSlice({
  name: 'threads',
  initialState,
  reducers: {
    addNewThread: (state) => {
      state.threads.forEach((thread) => {
        thread.isActive = false;
      });
      const newThread = createTodayThread();
      state.threads.push(newThread);
    },
    setActiveThread: (state, action: PayloadAction<string>) => {
      state.threads.forEach((thread) => {
        thread.isActive = thread.id === action.payload;
      });
    },
    removeThread: (state, action: PayloadAction<string>) => {
      state.threads = state.threads.filter((thread) => thread.id !== action.payload);
    },
    updateThread: (state, action: PayloadAction<{ firstMessage: string; threadId: string }>) => {
      const new_thread = state.threads.find((thread) => thread.id === action.payload.threadId);
      if (new_thread && !new_thread?.firstMessage) {
        new_thread.firstMessage = action.payload.firstMessage;
      }
    },
    addLoadingThreadId: (state, action: PayloadAction<string>) => {
      if (!state.loadingThreadIds.includes(action.payload)) {
        state.loadingThreadIds.push(action.payload);
      }
    },
    removeLoadingThreadId: (state, action: PayloadAction<string>) => {
      state.loadingThreadIds = state.loadingThreadIds.filter((id) => id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      threadApi.endpoints.fetchThreads.matchFulfilled,
      (state, action: PayloadAction<Response<ChatThread[]>>) => {
        if (action.payload.data) {
          const updatedThreads: ChatThread[] = action.payload.data.map((thread) => ({
            ...thread,
            isActive: false,
          }));
          state.threads = [...state.threads, ...updatedThreads];
        }
      },
    );
  },
});

export const { addNewThread, removeThread, setActiveThread, updateThread, addLoadingThreadId, removeLoadingThreadId } =
  threadSlice.actions;
export default threadSlice.reducer;

export const selectCategorizedThreads = createSelector(
  (state: RootState) => state.thread.threads,
  (threads) => categorizeThreadsByDate(threads),
);

export const selectActiveThreadId = createSelector(
  (state: RootState) => state.thread.threads,
  (threads) => {
    const activeThread = threads.find((thread) => thread.isActive);
    if (!activeThread) {
      console.error('No active thread found.');
      return 'mockId';
    }
    return activeThread.id;
  },
);

export const selectDemoThread = createSelector(
  (state: RootState) => state.thread.threads,
  (threads) => {
    const demoThread = threads.find((thread) => thread.firstMessage.startsWith('DEMO'));
    return demoThread || null;
  },
);

export const selectIsThreadLoading = (threadId: string) => (state: RootState) => {
  return state.thread.loadingThreadIds.includes(threadId);
};
