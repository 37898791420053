import { createSlice, createEntityAdapter, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { chatApi } from '../../services/chatApi/chatApi';
import { RootState } from '../store';
import { Service } from '../service/serviceSlice';
import { threadApi } from '../../services/threadApi/threadApi';
import { Response } from '../../utils/types';
import { mockOnboardingMessageDE } from '../../../mocks/mockOnboardingMessageDE';
import { mockOnboardingMessageEN } from '../../../mocks/mock.onboardingMessageEN';
import { resetStep, Steps } from '../onboarding/onboardingSlice';

const roles = ['assistant', 'human'] as const;

export type Role = (typeof roles)[number];

export type Message = {
  id: number;
  role: Role;
  content: string;
  type: 'text' | 'file';
  threadId: string;
  timestamp: string;
  isOnboardingQuestion?: boolean;
  userId?: string;
};

const messagesAdapter = createEntityAdapter<Message>({
  sortComparer: (a, b) => a.id - b.id,
});

const initialState = {
  ...messagesAdapter.getInitialState(),
  isLoading: false,
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<Omit<Message, 'id'> & { service?: Service }>) => {
      messagesAdapter.addOne(state, { ...action.payload, id: state.ids.length + 1 });
    },
    addManyMessages: (state, action: PayloadAction<(Omit<Message, 'id'> & { service?: Service })[]>) => {
      const messagesWithIds = action.payload.map((message, index) => ({
        ...message,
        id: state.ids.length + 1 + index,
      }));
      messagesAdapter.addMany(state, messagesWithIds);
    },
    clearMessages: (state) => {
      messagesAdapter.removeAll(state);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetStep, (state) => {
      const demoThreadId = state.ids.find((id) => {
        const message = state.entities[id];
        return message && message.content.startsWith('DEMO:');
      });

      if (demoThreadId) {
        const remainingMessages = state.ids.filter(
          (id) => state.entities[id]?.threadId !== state.entities[demoThreadId]?.threadId,
        );
        messagesAdapter.setAll(
          state,
          remainingMessages.map((id) => state.entities[id]!),
        );
      }
    });
    builder.addMatcher(chatApi.endpoints.sendMessage.matchPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      chatApi.endpoints.sendMessage.matchFulfilled,
      (state, action: PayloadAction<Response<Message>>) => {
        state.isLoading = false;
        const { meta } = action;
        const isOnboarding = meta?.arg?.originalArgs?.meta?.isOnboarding;
        const lang = meta?.arg?.originalArgs?.meta?.currentLanguage;
        const onboardingStep: Steps = meta?.arg?.originalArgs?.meta?.nextStepIndex;
        const messageData =
          isOnboarding && onboardingStep
            ? {
                content:
                  lang === 'de-DE'
                    ? mockOnboardingMessageDE[onboardingStep].answer
                    : mockOnboardingMessageEN[onboardingStep].answer,
                role: 'assistant',
                type: 'text',
                threadId: meta.arg.originalArgs.threadId,
              }
            : action.payload.data;

        if (messageData) {
          messagesAdapter.addOne(state, {
            ...messageData,
            isOnboardingQuestion: isOnboarding,
            id: state.ids.length + 1,
            timestamp: Date.now().toString(),
          });
        }
      },
    );
    builder.addMatcher(chatApi.endpoints.sendMessage.matchRejected, (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(
      threadApi.endpoints.fetchMessagesByThreadId.matchFulfilled,
      (state, action: PayloadAction<Response<Message[]>>) => {
        const messagesWithIds =
          action.payload.data &&
          action.payload.data.map((message, index) => ({
            ...message,
            id: state.ids.length + 1 + index,
          }));
        if (Array.isArray(messagesWithIds) && messagesWithIds[0].threadId !== 'mockId') {
          messagesAdapter.addMany(state, messagesWithIds);
        }
      },
    );
    builder.addMatcher(threadApi.endpoints.fetchMessagesByThreadId.matchRejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { addMessage, addManyMessages, clearMessages, setLoading } = chatSlice.actions;

export default chatSlice.reducer;

const {
  selectAll: getAllMessages,
  selectById: getMessageById,
  selectIds: getMessageIds,
  selectEntities: getMessageEntities,
  selectTotal: getTotalMessages,
} = messagesAdapter.getSelectors((state: RootState) => state.chat);

export const selectMessageById = (messageId: number) =>
  createSelector(
    (state: RootState) => state,
    (state) => getMessageById(state, messageId),
  );

export const selectAllMessages = createSelector(
  (state: RootState) => state,
  (state) => getAllMessages(state),
);

export const selectMessageEntities = createSelector(
  (state: RootState) => state,
  (state) => getMessageEntities(state),
);

export const selectTotalMessageCount = createSelector(
  (state: RootState) => state,
  (state) => getTotalMessages(state),
);

export const selectMessagesByRole = (role: Role) =>
  createSelector(selectAllMessages, (messages) => messages.filter((message) => message.role === role));

export const selectLastUserMessageBefore = (messageId: number) =>
  createSelector(
    (state: RootState) => getAllMessages(state),
    (messages) => {
      const currentIndex = messages.findIndex((message) => message.id === messageId);
      if (currentIndex === -1) {
        return null;
      }

      for (let i = currentIndex - 1; i >= 0; i--) {
        if (messages[i].role === 'human') {
          return messages[i];
        }
      }
      return null;
    },
  );

export const selectMessagesByThreadId = (threadId: string) =>
  createSelector(
    (state: RootState) => getAllMessages(state),
    (messages) => messages.filter((message) => message.threadId === threadId),
  );

export const selectIsLoading = (state: RootState) => state.chat.isLoading;
