import Button from '@rio-cloud/rio-uikit/components/button/Button';
import { useNavigate } from 'react-router';
import routes from '../../../../routes/routes';
import { getUserProfile } from '../../../../configuration/login/loginSlice';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import { useEffect, useRef } from 'react';

const HeroSection = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const user = useSelector(getUserProfile);
  const isDarkMode = useDarkMode();

  const pageTopRef = useRef<HTMLDivElement>(null);

  const handleStartConversation = () => navigate(routes.chat);

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Container isDarkMode={isDarkMode}>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <Content>
        <h2 className="h2">
          {intl.formatMessage(
            { id: 'getStartedPage.heroSection.greeting' },
            { name: <span className="text-color-primary">{user?.name}!</span> },
          )}
        </h2>
        <h3 className="h5">
          <FormattedMessage id="getStartedPage.heroSection.introduction" />
        </h3>
        <p>
          <FormattedMessage id="getStartedPage.heroSection.description" />
        </p>
        <Button bsStyle={Button.PRIMARY} onClick={handleStartConversation}>
          <FormattedMessage id="getStartedPage.common.startChat.label" />
        </Button>
      </Content>
      <Image src="assets/images/hero-image.svg" alt="Hero" />
    </Container>
  );
};

export default HeroSection;

type ContainerProps = {
  isDarkMode: boolean;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  background-position: right;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  @media (min-width: 1200px) {
    background-image: ${(props) =>
      props.isDarkMode ? 'url(assets/images/grid-template-dark.svg)' : 'url(assets/images/grid-template.svg)'};
  }

  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: var(--color-white);
    clip-path: polygon(0 100%, 100% 0, 100% 100%);
    z-index: -1;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 1rem;
    background-position: center;
    background-image: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  max-width: 50%;
  padding: 1rem;
  z-index: 1;

  @media (max-width: 768px) {
    max-width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const Image = styled.img`
  max-width: 40%;
  position: relative;
  top: 30px;
  z-index: 1;

  @media (max-width: 768px) {
    max-width: 60%;
    top: 0;
    margin-top: 1rem;
  }
`;
