import { FormattedMessage } from 'react-intl';
import { NavLink, Link, useNavigate, useLocation } from 'react-router-dom';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';
import { config } from '../../../config';
import routes from '../../../routes/routes';
import ServiceInfo from './components/ServiceInfo/ServiceInfo';
import { useProtectedAccess } from '../../../hooks/useProtectedAccess';

const Header = () => {
  const navigate = useNavigate();
  const hasAccess = useProtectedAccess();
  const location = useLocation();
  const navItems = [
    {
      key: routes.homepage,
      route: (
        <NavLink to={routes.homepage} onClick={() => navigate(routes.getStarted)}>
          <FormattedMessage id="common.gettingStarted.label" />
        </NavLink>
      ),
    },
    {
      key: routes.chat,
      route: (
        <NavLink to={routes.chat} onClick={() => navigate(routes.chat)}>
          <FormattedMessage id="common.chat.label" />
        </NavLink>
      ),
    },
    {
      key: routes.feedback,
      route: (
        <NavLink to={routes.feedback} onClick={() => navigate(routes.feedback)}>
          <FormattedMessage id="common.feedback.label" />
        </NavLink>
      ),
    },
  ];

  const environment = import.meta.env.PROD ? 'production' : 'local';

  const serviceInfoItem = <ServiceInfo />;
  const notifications = <RioNotifications />;
  const userMenuItem = <DefaultUserMenu environment={environment} />;
  return (
    <ApplicationHeader
      label={<FormattedMessage id="common.app.title" defaultMessage="Fleet Assistant" />}
      appNavigator={<IframeResizer className="iFrameResizer" src={config.backend.MENU_SERVICE} />}
      homeRoute={<Link to={config.homeRoute || ''} />}
      navItems={hasAccess ? navItems : []}
      actionBarItems={[serviceInfoItem, notifications, userMenuItem]}
    />
  );
};

export default Header;
