import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChatThread } from '../../store/thread/threadSlice';
import { prepareHeaders } from '../../utils/prepareHeaders';
import { Response } from '../../utils/types';
import { Message } from '../../store/chat/chat.slice';

type ThreadRequest = {
  userId: string;
  threadId: string;
};

export const threadApi = createApi({
  reducerPath: 'threadApi',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://localhost/v1/chat/', prepareHeaders }),
  endpoints: (builder) => ({
    fetchThreads: builder.query<Response<ChatThread[]>, ThreadRequest>({
      query: ({ userId }) => `/threads/${userId}`,
    }),
    addThread: builder.mutation<Response<Partial<ChatThread[]>>, ChatThread>({
      query: (newThread) => ({
        url: '/threads',
        method: 'POST',
        body: newThread,
      }),
    }),
    deleteThread: builder.mutation<Response, string>({
      query: (threadId: string) => ({
        url: `threads/${threadId}`,
        method: 'DELETE',
      }),
    }),
    fetchMessagesByThreadId: builder.query<Response<Message[]>, ThreadRequest>({
      query: ({ userId, threadId }) => `chat/messages?user_id=${userId}&thread_id=${threadId}`,
    }),
    fetchFavoriteThreads: builder.query<Response<ChatThread[]>, ThreadRequest>({
      query: ({ userId }) => `/threads/${userId}/favorites`,
    }),
    addToFavorites: builder.mutation<Response, ThreadRequest>({
      query: ({ userId, threadId }) => ({
        url: `/threads/${userId}/favorites/${threadId}`,
        method: 'POST',
      }),
    }),
    deleteFromFavorites: builder.mutation<Response, ThreadRequest>({
      query: ({ userId, threadId }) => ({
        url: `/threads/${userId}/favorites/${threadId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFetchThreadsQuery,
  useFetchFavoriteThreadsQuery,
  useAddThreadMutation,
  useDeleteThreadMutation,
  useFetchMessagesByThreadIdQuery,
  useAddToFavoritesMutation,
  useDeleteFromFavoritesMutation,
} = threadApi;
