import { FormattedMessage } from 'react-intl';
import PageNavigation from '../PageNavigation/PageNavigation';
import styled from 'styled-components';
import { useEffect, useRef } from 'react';

const promptText = {
  tipsHeading: 'getStartedPage.howWritePrompts.tipsForGettingStarted.title',
  tips: [
    'getStartedPage.howWritePrompts.tipsForGettingStarted.tip_one',
    'getStartedPage.howWritePrompts.tipsForGettingStarted.tip_two',
    'getStartedPage.howWritePrompts.tipsForGettingStarted.tip_three',
  ],
  doHeading: 'getStartedPage.howWritePrompts.do.title',
  doTips: [
    {
      tip: 'getStartedPage.howWritePrompts.doTips.tip_one',
      example: 'getStartedPage.howWritePrompts.doTips.tip_one_example',
    },
    {
      tip: 'getStartedPage.howWritePrompts.doTips.tip_two',
      example: 'getStartedPage.howWritePrompts.doTips.tip_two_example',
    },
    {
      tip: 'getStartedPage.howWritePrompts.doTips.tip_three',
      example: 'getStartedPage.howWritePrompts.doTips.tip_three_example',
    },
    {
      tip: 'getStartedPage.howWritePrompts.doTips.tip_four',
      example: null,
    },
  ],
  dontHeading: 'getStartedPage.howWritePrompts.dont.title',
  dontTips: ['getStartedPage.howWritePrompts.dontTips.tip_one', 'getStartedPage.howWritePrompts.dontTips.tip_two'],
};

type HowWritePromptsProps = {
  handleNextSection: () => void;
  handlePreviousSection: () => void;
};

const HowWritePrompts = ({ handleNextSection, handlePreviousSection }: HowWritePromptsProps) => {
  const pageTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);
  return (
    <div className="margin-top-25">
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <div className="panel panel-default panel-body panel-blank position-relative padding-x-25">
        <div>
          <div className="lead">
            <FormattedMessage id={promptText.tipsHeading} />
          </div>
          <ul className="icon-list margin-bottom-0">
            {promptText.tips.map((tip, index) => (
              <li key={index}>
                <span className="rioglyph rioglyph-ok-sign text-color-success position-relative bottom-2" />
                <span>
                  <FormattedMessage id={tip} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="panel panel-default panel-body panel-blank position-relative padding-x-25">
        <div>
          <div className="lead">
            <FormattedMessage id={promptText.doHeading} />
          </div>
          <ul className="icon-list margin-bottom-0">
            {promptText.doTips.map((tipItem, index) => (
              <li key={index} className="display-flex flex-column gap-5">
                <div>
                  <span className="rioglyph rioglyph-ok-sign text-color-success margin-right-10 text-size-18 position-relative top-2" />
                  <span>
                    <FormattedMessage id={tipItem.tip} />
                  </span>
                </div>
                {tipItem.example && (
                  <QuoteContainer>
                    <Quote>
                      <FormattedMessage id={tipItem.example} />
                    </Quote>
                  </QuoteContainer>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="panel panel-default panel-body panel-blank position-relative padding-x-25">
        <div>
          <div className="lead">
            <FormattedMessage id={promptText.dontHeading} />
          </div>
          <ul className="icon-list margin-bottom-0">
            {promptText.dontTips.map((tip, index) => (
              <li key={index}>
                <span className="rioglyph rioglyph-remove-sign text-color-danger position-relative bottom-3" />
                <span>
                  <FormattedMessage id={tip} />
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="panel panel-default panel-body panel-blank position-relative padding-x-25">
        <PageNavigation
          onNext={handleNextSection}
          onPrevious={handlePreviousSection}
          previousLabel="getStartedPage.howVirtualAssistantWorks.label"
          nextLabel="getStartedPage.tools.label"
        />
      </div>
    </div>
  );
};

export default HowWritePrompts;

const QuoteContainer = styled.div`
  margin-top: 1rem;
  background-color: var(--gray-lightest);
  padding: 1rem;
`;

const Quote = styled.blockquote`
  margin: 0;
  font-style: italic;
  font-size: 1.4rem;
  color: var(--gray-darkest);
`;
