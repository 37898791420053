import Dialog from '@rio-cloud/rio-uikit/Dialog';
import ExpanderPanelWarning from '../../../pages/GetStartedPage/components/ExpanderPanelWarning/ExpanderPanelWarining';
import Checkbox from '@rio-cloud/rio-uikit/components/checkbox/Checkbox';
import { useIntl } from 'react-intl';

type SafetyNoticeProps = {
  show: boolean;
  checked: boolean;
  setShow: (value: boolean) => void;
  onClose: () => void;
  handleOnChange: () => void;
};

const SafetyNotice = ({ show, checked, onClose, setShow, handleOnChange }: SafetyNoticeProps) => {
  const intl = useIntl();
  const footer = (
    <div>
      <button type="button" className="btn btn-primary btn-outline" onClick={() => setShow(false)}>
        <span className="rioglyph rioglyph-ok" aria-hidden="true" />
        <span className="text-capitalize">
          {intl.formatMessage({ id: 'getStartedPage.expanderPanelWarning.buttonConfirm' })}
        </span>
      </button>
    </div>
  );

  const body = (
    <div>
      <ExpanderPanelWarning />
      <div className="padding-x-10">
        <Checkbox
          onChange={handleOnChange}
          checked={checked}
          custom
          className="padding-15 user-select-none hover-bg-highlight-decent"
        >
          <div className="checkbox-text-wrapper display-flex gap-10">
            <div className="checkbox-text" />
            <div className="flex-1-1">
              <div className="text-medium text-size-16 text-color-darker">
                {intl.formatMessage({ id: 'getStartedPage.expanderPanelWarning.doNotShowAgain' })}
              </div>
              <div className="text-color-dark">
                {intl.formatMessage({ id: 'getStartedPage.expanderPanelWarning.doNotShowAgain.description' })}
              </div>
            </div>
          </div>
        </Checkbox>
      </div>
    </div>
  );

  return (
    <Dialog
      show={show}
      title={intl.formatMessage({ id: 'getStartedPage.expanderPanelWarning.title' })}
      footer={footer}
      body={body}
      showCloseButton
      bsSize="md"
      useOverflow
      onClose={onClose}
    />
  );
};

export default SafetyNotice;
