import Button from '@rio-cloud/rio-uikit/Button';
import ChatThreadCategory from '../ChatThreadCategory/ChatThreadCategory';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { getUserProfile } from '../../../../configuration/login/loginSlice';
import { useFetchThreadsQuery } from '../../../../services/threadApi/threadApi';
import { selectCategorizedThreads, ChatThread, addNewThread } from '../../../../store/thread/threadSlice';
import { getUserId } from '../../../../utils/getUserId';
import { selectIsLoading } from '../../../../store/chat/chat.slice';

const translations = {
  addNewChat: 'chatPage.chatThreadList.threadDeletion.addNewChat.label',
  today: 'chatPage.chatTheadList.today.label',
  last7Days: 'chatPage.chatTheadList.last7Days.label',
  last30Days: 'chatPage.chatTheadList.last30Days.label',
  older: 'chatPage.chatTheadList.older.label',
} as const;

const ChatHistoryList = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUserProfile);
  const { data } = useFetchThreadsQuery({ userId: getUserId(user?.sub ?? '') });
  const isLoading = useSelector(selectIsLoading);
  const threadsList = useSelector(selectCategorizedThreads);

  const hasValidThreads = (threads: ChatThread[]) => {
    return threads.length > 0 && threads.some((thread) => thread.firstMessage.trim() !== '');
  };

  const handleOnClick = () => dispatch(addNewThread());

  return (
    <div>
      <div className="padding-x-10 display-flex flex-column justify-content-start height-100pct">
        <div className="padding-bottom-20">
          <div className="display-flex justify-content-start margin-top-20 margin-x-10">
            <Button
              bsStyle="primary"
              iconName="rioglyph rioglyph-pencil-square"
              disabled={isLoading}
              onClick={handleOnClick}
            >
              <FormattedMessage id={translations.addNewChat} />
            </Button>
          </div>
          <div className="padding-10">
            {hasValidThreads(threadsList.today) && (
              <ChatThreadCategory title={translations.today} threads={threadsList.today} />
            )}
            {hasValidThreads(threadsList.last7Days) && (
              <ChatThreadCategory title={translations.last7Days} threads={threadsList.last7Days} />
            )}
            {hasValidThreads(threadsList.last30Days) && (
              <ChatThreadCategory title={translations.last30Days} threads={threadsList.last30Days} />
            )}
            {hasValidThreads(threadsList.older) && (
              <ChatThreadCategory title={translations.older} threads={threadsList.older} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHistoryList;
