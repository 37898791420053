import { Fragment } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ChatWindow from './components/ChatWindow/ChatWindow';
import ChatAssetTree from './components/ChatAssetTree/ChatAssetTree';

const ChatPage = () => (
  <Fragment>
    <ChatAssetTree />
    <ApplicationLayout.Body>
      <ChatWindow />
    </ApplicationLayout.Body>
  </Fragment>
);

export default ChatPage;
