import { useState } from 'react';
import styled, { css } from 'styled-components';
import Popover from '@rio-cloud/rio-uikit/components/popover/Popover';
import OverlayTrigger from '@rio-cloud/rio-uikit/components/overlay/OverlayTrigger';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { removeThread, selectActiveThreadId, setActiveThread } from '../../../../store/thread/threadSlice';
import { getUserProfile } from '../../../../configuration/login/loginSlice';
import {
  useAddToFavoritesMutation,
  useDeleteFromFavoritesMutation,
  useDeleteThreadMutation,
  useFetchMessagesByThreadIdQuery,
} from '../../../../services/threadApi/threadApi';
import { getUserId } from '../../../../utils/getUserId';

type ThreadListItemProps = {
  threadId: string;
  firstMessage: string;
  isFavorite: boolean;
};

const translations = {
  removeFromFavorites: 'chatPage.chatThreadList.threadRemoveFromFavorites.label',
  addToFavorites: 'chatPage.chatThreadList.threadAddToFavorites.label',
  editThreadTitle: 'chatPage.chatThreadList.editThreadTitle.label',
  threadDeletion: 'chatPage.chatThreadList.threadDeletion.label',
  threadDeletionSuccess: 'chatPage.chatThreadList.threadDeletion.success.notification',
  removeFromFavoritesSuccess: 'chatPage.chatThreadList.threadRemoveFromFavorites.success.notification',
  threadDeletionError: 'chatPage.chatThreadList.threadRemoveFromFavorites.error.notification',
  addToFavoritesSuccess: 'chatPage.chatThreadList.threadAddToFavorites.success.notification',
  toggleFavoritesError: 'chatPage.chatThreadList.threadToggleFavorites.error.notification',
} as const;

const ThreadListItem = ({ threadId, firstMessage, isFavorite }: ThreadListItemProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const activeThread = useSelector(selectActiveThreadId);
  const [showPopover, setShowPopover] = useState(false);

  const [addToFavorites] = useAddToFavoritesMutation();
  const [deleteFromFavorites] = useDeleteFromFavoritesMutation();
  const [deleteThread] = useDeleteThreadMutation();
  const {
    data: messages,
    error,
    isLoading,
  } = useFetchMessagesByThreadIdQuery(
    { userId, threadId: activeThread },
    {
      skip: activeThread !== threadId,
    },
  );

  const handleRemoveThread = async () => {
    try {
      await deleteThread(threadId).unwrap();
      dispatch(removeThread(threadId));
      Notification.success(intl.formatMessage({ id: translations.threadDeletionSuccess }));
    } catch (err) {
      Notification.error(intl.formatMessage({ id: translations.threadDeletionError }));
    }
  };

  const handleEditThreadTitle = async () => {
    console.log(`TODO: Edit title: ${threadId}`);
  };

  const handleToggleFavorite = async () => {
    try {
      if (isFavorite) {
        await deleteFromFavorites({ userId, threadId }).unwrap();
        Notification.success(intl.formatMessage({ id: translations.removeFromFavoritesSuccess }));
      } else {
        await addToFavorites({ userId, threadId }).unwrap();
        Notification.success(intl.formatMessage({ id: translations.addToFavoritesSuccess }));
      }
    } catch (err) {
      Notification.error(intl.formatMessage({ id: translations.toggleFavoritesError }));
    }
  };

  const handleItemClick = () => {
    setShowPopover((prev) => !prev);
    dispatch(setActiveThread(threadId));
  };

  const handleOnClick = async () => {
    dispatch(setActiveThread(threadId));
    await useFetchMessagesByThreadIdQuery({ userId, threadId });
  };

  return (
    <StyledThreadListItem key={threadId} isActive={activeThread === threadId} onClick={handleOnClick}>
      <EllipsisText>{firstMessage}</EllipsisText>
      <OverlayTrigger
        show={showPopover}
        onToggle={handleItemClick}
        trigger="click"
        placement="bottom-end"
        overlay={
          <CustomPopover id="popover-positioned-bottom-end">
            <PopoverContent>
              <PopoverItem onClick={() => handleToggleFavorite()}>
                <span className="display-flex gap-5 align-items-center">
                  <span className="rioglyph-icon-pair">
                    <span className="rioglyph rioglyph-star" />
                    <span className={`rioglyph rioglyph-${isFavorite ? 'minus' : 'plus'}`} />
                  </span>
                  <span>
                    <FormattedMessage
                      id={isFavorite ? translations.removeFromFavorites : translations.addToFavorites}
                    />
                  </span>
                </span>
              </PopoverItem>
              <PopoverItem onClick={() => handleEditThreadTitle()}>
                <span className="display-flex gap-5 align-items-center">
                  <span className="rioglyph rioglyph-pencil" />
                  <span>
                    <FormattedMessage id={translations.editThreadTitle} />
                  </span>
                </span>
              </PopoverItem>
              <PopoverItem onClick={() => handleRemoveThread()}>
                <span className="display-flex gap-5 align-items-center">
                  <span className="rioglyph rioglyph-trash" />
                  <span>
                    <FormattedMessage id={translations.threadDeletion} />
                  </span>
                </span>
              </PopoverItem>
            </PopoverContent>
          </CustomPopover>
        }
      >
        <TriggerIcon
          className="rioglyph rioglyph-option-horizontal text-size-14 padding-top-5 hover-text-color-darkest text-color-darkest"
          aria-hidden="true"
        />
      </OverlayTrigger>
    </StyledThreadListItem>
  );
};

export default ThreadListItem;

const EllipsisText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 1.5rem 0 0;
  flex: 1;
`;

const StyledThreadListItem = styled.li<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  ${({ isActive }) =>
    isActive &&
    css`
      background-color: var(--color-highlight-lightest);
    `}

  &:hover {
    background-color: var(--gray-decent);
  }
`;

const TriggerIcon = styled.span`
  font-size: 1.5rem;
`;

const CustomPopover = styled(Popover)`
  border-radius: 8px;
  & .arrow {
    display: none;
  }
  & .popover-content {
    padding: 0 !important;
  }
`;

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const PopoverItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: var(--gray-decent);
  }
`;
