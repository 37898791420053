import styled from 'styled-components';
import { Service } from '../../../../store/service/serviceSlice';

type ServiceCardProps = {
  description: string;
  iconClassName: string;
  service: Service;
  onClickFunction: (value: string, service: Service) => void;
};

const ServiceCard = ({ description, service, iconClassName, onClickFunction }: ServiceCardProps) => {
  return (
    <ServiceCardWrapper
      className="
            panel panel-primary panel-body panel-blank shadow-default padding-15 margin-bottom-0
            position-relative hover-scale-105 transition-all transition-duration-03 transition-ease-in-out 
        "
      onClick={() => onClickFunction(description, service)}
    >
      <span className={`rioglyph ${iconClassName} text-size-300pct`} />
      <span className="position-absolute left--1 top-15 height-25 width-3 bg-primary" />
      <StyledParagraph className="text-color-darker margin-top-4 text-size-11">{description}</StyledParagraph>
    </ServiceCardWrapper>
  );
};

export default ServiceCard;

const ServiceCardWrapper = styled.div`
  cursor: pointer;
  max-width: 180px;
  height: 180px;
`;

const StyledParagraph = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  margin: 0;
`;
