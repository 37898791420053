import { MockMessages } from "../src/store/onboarding/onboardingSlice";

export const mockOnboardingMessageDE: MockMessages = {
    'step-1': {
        question: "Welche Fahrer benötigen Unterstützung aufgrund einer Gesamtbewertung unter 70 im Durchschnitt im vergangenen Monat und wie lauten ihre Bewertungen?",
        answer:
            "Die folgenden Fahrer benötigen Unterstützung aufgrund einer durchschnittlichen Gesamtbewertung von unter **70** im letzten Monat:\n\n" +
            "| Fahrer               | Durchschnittliche Gesamtbewertung |\n" +
            "|----------------------|-----------------------------------|\n" +
            "| Max Mustermann       | **25.0**                          |\n" +
            "| Jan Kowalski         | **26.0**                          |\n" +
            "| John Doe             | **26.0**                          |\n" +
            "| Morten Menigmand     | **27.0**                          |\n" +
            "| Jean Dupont          | **44.0**                          |\n" +
            "| Mario Rossi          | **44.0**                          |\n" +
            "| Jan Janssen          | **45.0**                          |\n" +
            "| Jane Doe             | **48.0**                          |\n\n" +
            "Diese Fahrer sollten zusätzliche Unterstützung und Schulungen erhalten, um ihre Gesamtleistung zu verbessern und die angestrebte durchschnittliche Gesamtbewertung von **70** oder mehr zu erreichen.\n\n"
    },
    'step-2': {
        question: "Was ist der Grund für die niedrige Bewertung von Max Mustermann       ?",
        answer: "Der Grund für die niedrige Bewertung von **Max Mustermann       ** im letzten Monat scheint zu sein:\n\n" +
            "- Niedrige durchschnittliche Bremspedal-Bewertung von **15.0**\n\n" +
            "Die niedrige Bremspedalbewertung deutet darauf hin, dass Max Mustermann        in diesem Zeitraum Probleme mit dem gleichmäßigen und kontrollierten Bremsen hatte. Diese Faktoren haben wahrscheinlich zu der niedrigen Gesamtbewertung von 25,0 für Max Mustermann        im letzten Monat beigetragen.\n\n"
    },
    'step-3': {
        question: "Wie setzt sich die Bremsleistung zusammen?",
        answer: "Die **Bremsleistung** setzt sich wie folgt zusammen:\n\n" +
            "1. Verhältnis von verschleißfreier Bremse zu Betriebsbremse:\n" +
            "    - Das Rating basiert auf dem Verhältnis der Bremsstrecke, die mit der verschleißfreien Bremse (z.B. Retarder, Motorbremse) im Vergleich zur Betriebsbremse zurückgelegt wird.\n" +
            "    - Ein **Verhältnis von 5:1 oder höher** für die verschleißfreie Bremse ergibt eine **100%ige Bremsleistung**.\n" +
            "    - Ein **Verhältnis von 0,5:1 oder niedriger** für die verschleißfreie Bremse ergibt eine **0%ige Bremsleistung**.\n\n" +
            "2. Anteil der Strecke, der mit der Betriebsbremse abgebremst wird:\n" +
            "    - Die Bewertung berücksichtigt auch den Anteil der Gesamtfahrstrecke, der mit der Betriebsbremse abgebremst wird.\n" +
            "    - **Weniger als 1%** der Strecke, die mit der Betriebsbremse abgebremst wird, ergibt eine **100%ige Bremsleistung**.\n" +
            "    - **Mehr als 5,5%** der Strecke, die mit der Betriebsbremse abgebremst wird, ergibt eine **0%ige Bremsleistung**.\n\n" +
            "3. Gesamtbewertung der Bremsleistung:\n" +
            "    - Die Gesamtbewertung der Bremsleistung ist der berechnete Durchschnittswert aus Teilbewertungen wie Gleitphase, Tempomat, Gaspedal, Bremsen und Geschwindigkeitsüberschreitung.\n" +
            "    - Je höher der Gesamtwert, desto besser ist die Bremsleistung.\n\n" +
            "Zusammengefasst ist **die Bremsleistung** eine Kennzahl, die das Verhältnis der Nutzung von verschleißfreier Bremse zu Betriebsbremse sowie den Anteil der Gesamtstrecke, der mit der Betriebsbremse abgebremst wird, berücksichtigt. Eine hohe Bremsleistung erfordert die Minimierung des Einsatzes der Betriebsbremse und die Maximierung der Nutzung von verschleißfreien Bremssystemen.\n\n" +
            "---\n\n" +
            "Basierend auf den folgenden Dokumenten wurde die Antwort generiert (nach Relevanz sortiert):\n\n" +
            "1. [Webinar_2 - Seite 3 - Teil 14](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)\n" +
            "2. [Webinar_3 - Seite 1 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=450)\n" +
            "3. [Webinar_3 - Seite 2 - Teil 18](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=71)\n" +
            "4. [Webinar_2 - Seite 2 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n" +
            "5. [Webinar_1 - Seite 1 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "6. [Logging_csv - Seite 13 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "7. [Webinar_3 - Seite 2 - Teil 12](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=15)\n" +
            "8. [Webinar_2 - Seite 2 - Teil 21](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Manual.pdf#page=99)\n" +
            "9. [Webinar_1 - Seite 2 - Teil 17](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Logging_csv.pdf#page=16)\n" +
            "10. [Webinar_3 - Seite 3 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Total_Rating.pdf#page=3)"
    },
    'step-4': {
        question: "Was kann mein Fahrer Max Mustermann       , der ein niedriges Bremsrating hat, tun, um sich zu verbessern?",
        answer: "**Max Mustermann       ** oder andere Fahrer mit einem niedrigen Bremsrating können Folgendes tun, um sich zu verbessern:\n\n" +
            "1. Erhöhung der Nutzung von verschleißfreien Bremsen:\n" +
            "    - Ein hohes Verhältnis der Bremsstrecke mit den verschleißfreien Bremsen (z.B. Retarder, Motorbremse) im Vergleich zur Betriebsbremse ist entscheidend für ein gutes Bremsrating.\n" +
            "    - Fahrer sollten dazu angehalten werden, die verschleißfreien Bremssysteme so weit wie möglich zu nutzen, anstatt sich stark auf die Betriebsbremse zu verlassen.\n\n" +
            "2. Reduzierung der Nutzung der Betriebsbremse:\n" +
            "    - Das Bremsrating bestraft Fahrer, die die Betriebsbremse für mehr als 1% der Gesamtfahrstrecke verwenden.\n" +
            "    - Fahrer sollten darauf achten, die Nutzung der Betriebsbremse zu minimieren, indem sie Stopps antizipieren, ausrollen lassen und die verschleißfreien Bremssysteme nutzen.\n\n" +
            "3. Sanftes und kontrolliertes Bremsen:\n" +
            "    - Ein sanftes, dosiertes Bremsen gegenüber abruptem Einsatz der Betriebsbremse wird bevorzugt.\n" +
            "    - Fahrer sollten Techniken für weiches, kontrolliertes Bremsen üben, um den Verschleiß der Bremskomponenten zu reduzieren und das Bremsrating zu verbessern.\n\n" +
            "4. Nutzung von Assistenzsystemen:\n" +
            "    - Funktionen wie Tempomat und Geschwindigkeitsbegrenzer können Fahrer dabei unterstützen, angemessene Geschwindigkeiten beizubehalten und den Bedarf für starkes Bremsen zu reduzieren.\n" +
            "    - Die Förderung des Einsatzes dieser Technologien kann das Bremsrating indirekt verbessern.\n\n" +
            "5. Vorausschauendes Fahren:\n" +
            "    - Durch vorausschauendes Fahren und frühzeitiges Lösen des Gaspedals können Fahrer die Notwendigkeit zum Einsatz der Betriebsbremse minimieren.\n" +
            "    - Dieses **Ausrollen-Lassen** trägt zur Verbesserung des Bremsratings bei.\n\n" +
            "-Der Schlüssel für Fahrer ist es, den Einsatz von verschleißfreien Bremssystemen zu maximieren, die Nutzung der Betriebsbremse zu minimieren und sanfte, kontrollierte Bremstechniken anzuwenden. Entsprechendes Training und der Einsatz der verfügbaren Fahrzeugtechnologien können Fahrer dabei helfen, ein besseres Bremsrating zu erreichen.\n\n" +
            "---\n\n" +
            "Basierend auf den folgenden Dokumenten wurde die Antwort generiert (nach Relevanz sortiert):\n\n" +
            "1. [Webinar_1 - Seite 1 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_1.pdf#page=1)\n" +
            "2. [Webinar_3 - Seite 2 - Teil 13](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_3.pdf#page=2)\n" +
            "3. [FAQ_RIO - Seite 2 - Teil 1](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/FAQ_RIO.pdf#page=2)\n" +
            "4. [Webinar_3 - Seite 3 - Teil 13](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_3.pdf#page=3)\n" +
            "5. [Webinar_3 - Seite 3 - Teil 4](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_3.pdf#page=3)\n" +
            "6. [Webinar_1 - Seite 1 - Teil 3](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_1.pdf#page=1)\n" +
            "7. [Webinar_2 - Seite 3 - Teil 13](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_2.pdf#page=3)\n" +
            "8. [Webinar_1 - Seite 3 - Teil 18](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_1.pdf#page=3)\n" +
            "9. [Webinar_2 - Seite 3 - Teil 14](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_2.pdf#page=3)\n" +
            "10. [Webinar_2 - Seite 2 - Teil 21](https://fleet-manager-insights-bot.s3.eu-west-3.amazonaws.com/rag_docs/pdf/Webinar_2.pdf#page=2)"
    }
};