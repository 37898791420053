import { Fragment, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ChatInput from '../ChatInput/ChatInput';
import { useDispatch, useSelector } from 'react-redux';
import Notification from '@rio-cloud/rio-uikit/components/notification/Notification';
import { getUserProfile } from '../../../../configuration/login/loginSlice';
import SpeechBubble from '../SpeechBubble/SpeechBubble';
import ServicesSection from '../ServicesSection/ServicesSection';
import {
  useSendMessageMutation,
  useSendMessageToAskYourDocsMutation,
  useUploadFileMutation,
} from '../../../../services/chatApi/chatApi';
import { addMessage, Message, selectMessagesByThreadId } from '../../../../store/chat/chat.slice';
import { selectCurrentService, Service, setCurrentService } from '../../../../store/service/serviceSlice';
import {
  addLoadingThreadId,
  removeLoadingThreadId,
  selectActiveThreadId,
  selectIsThreadLoading,
  updateThread,
} from '../../../../store/thread/threadSlice';
import { generateRandomFileName } from '../../../../utils/generateRandomFileName/generateRandomFileName';
import { FormattedMessage, useIntl } from 'react-intl';
import { getLocale } from '../../../../configuration/lang/langSlice';
import { handleNextOnboardingStep, selectOnboardingStep, Steps } from '../../../../store/onboarding/onboardingSlice';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import SafetyNotice from '../../../../common/components/SafetyNotice/SafetyNotice';
import { getUserId } from '../../../../utils/getUserId';

const SAFETY_NOTICE_KEY = 'hiddenSafetyNoticeUntil';
const DAYS_30 = 30 * 24 * 60 * 60 * 1000;

type FileMessageProps = {
  fileName: string;
  fileSize: string;
};

const ChatWindow = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const user = useSelector(getUserProfile);
  const userId = getUserId(user?.sub ?? '');
  const currentService = useSelector(selectCurrentService);
  const currentThreadId = useSelector(selectActiveThreadId);
  const currentLanguage = useSelector(getLocale);
  const onboardingStep = useSelector(selectOnboardingStep);
  const messages = useSelector(selectMessagesByThreadId(currentThreadId));
  const isThreadsLoading = useSelector(selectIsThreadLoading(currentThreadId));

  const [sendMessage, { isLoading, isError, ...rest }] = useSendMessageMutation();
  const [sendMessageToAskYourDocs] = useSendMessageToAskYourDocsMutation();
  const [uploadFile] = useUploadFileMutation();

  const [showSafetyNotice, setShowSafetyNotice] = useState(false);
  const [isSafetyNoticeChecked, setIsSafetyNoticeChecked] = useState(localStorage.getItem(SAFETY_NOTICE_KEY));

  const startDate = parseInt(localStorage.getItem(SAFETY_NOTICE_KEY) ?? '0', 10);
  const hiddenUntil = new Date(startDate).getTime();
  const now = Date.now();

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSend = async (
    content: string | File,
    type: 'text' | 'file',
    service?: Service,
    isOnboarding: boolean = false,
    nextStepIndex?: Steps,
  ) => {
    let messageContent: string;

    if (!currentThreadId || currentThreadId === null) return;

    if (isOnboarding) dispatch(handleNextOnboardingStep());

    if (service) dispatch(setCurrentService(service));

    if (isFile(content)) {
      dispatch(setCurrentService('docs'));
      const fileSize = (content.size / 1024).toFixed(2) + ' KB';
      const fileMessage: FileMessageProps = {
        fileName: content.name,
        fileSize,
      };
      messageContent = JSON.stringify(fileMessage);

      if (type === 'text' && isString(content)) {
        dispatch(updateThread({ firstMessage: content, threadId: currentThreadId }));
      }

      dispatch(
        addMessage({
          content: messageContent,
          role: 'human',
          type,
          threadId: currentThreadId,
          timestamp: new Date().toISOString(),
          service: service || currentService,
        }),
      );

      try {
        dispatch(addLoadingThreadId(currentThreadId));
        await uploadFile({
          file: content,
          threadId: currentThreadId,
          file_name: generateRandomFileName(content.name),
          path: Date.now().toString(),
        }).unwrap();
      } catch (err) {
        console.error('Failed to upload the file: ', err);
      } finally {
        dispatch(removeLoadingThreadId(currentThreadId));
      }
    } else {
      messageContent = content;
      const requestMessage = {
        service,
        question: messageContent,
        language: currentLanguage === 'de-DE' ? 'Deutsch' : 'English',
        threadId: currentThreadId,
        meta: { isOnboarding, currentLanguage, onboardingStep, nextStepIndex },
        ...((service === 'training' || currentService === 'training') && { use_temp_db: false }),
        userId,
      };

      dispatch(
        addMessage({
          content: messageContent,
          role: 'human',
          type,
          threadId: currentThreadId,
          timestamp: new Date().toISOString(),
          service: service || currentService,
          isOnboardingQuestion: false,
        }),
      );

      if (type === 'text' && isString(content)) {
        dispatch(updateThread({ firstMessage: content, threadId: currentThreadId }));
      }

      try {
        dispatch(addLoadingThreadId(currentThreadId));
        let response;
        if (currentService !== 'docs') {
          response = await sendMessage(requestMessage).unwrap();
        } else {
          response = await sendMessageToAskYourDocs(requestMessage).unwrap();
        }
      } catch (err) {
        console.error('Failed to send the message: ', err);
      } finally {
        dispatch(removeLoadingThreadId(currentThreadId));
      }
    }
  };

  const handleSetShow = (value: boolean) => {
    setShowSafetyNotice(value);
  };

  useEffect(() => {
    if (now - hiddenUntil > DAYS_30) {
      setShowSafetyNotice(true);
    }
  }, []);

  const handleCheckSafetyNoticeDate = () => setIsSafetyNoticeChecked((prev) => !prev);

  useEffect(() => {
    if (isSafetyNoticeChecked) {
      localStorage.setItem(SAFETY_NOTICE_KEY, Date.now().toString());
    }
  }, [isSafetyNoticeChecked]);

  useEffect(() => {
    if (isError) {
      const errorMessage = rest?.error?.error ?? intl.formatMessage({ id: 'common.error' });
      Notification.error(intl.formatMessage({ id: 'common.error' }));
      dispatch(
        addMessage({
          content: errorMessage,
          role: 'assistant',
          type: 'text',
          threadId: currentThreadId,
          timestamp: new Date().toISOString(),
          service: currentService,
          isOnboardingQuestion: false,
        }),
      );
    }
  }, [isError]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isLoading]);

  return (
    <ChatWindowWrapper>
      {showSafetyNotice ? (
        <SafetyNotice
          show={showSafetyNotice}
          handleOnChange={handleCheckSafetyNoticeDate}
          checked={isSafetyNoticeChecked}
          setShow={handleSetShow}
          onClose={() => setShowSafetyNotice(false)}
        />
      ) : null}
      <ContentWrapper>
        {messages.length < 1 && (
          <Fragment>
            <WelcomeSection>
              <h3 className="h3">
                {intl.formatMessage(
                  { id: 'chatPage.chatWindow.greeting' },
                  { name: <span className="text-color-primary">{user?.name}!</span> },
                )}
              </h3>
              <h4 className="h4">
                <FormattedMessage id="chatPage.chatWindow.offerOfAssistance.inquiry" />
              </h4>
            </WelcomeSection>
            <ServicesSection
              onClickQuestion={(message, service, isOnboarding) =>
                handleSend(message, 'text', service, isOnboarding, 'step-1')
              }
            />
          </Fragment>
        )}
        <MessagesSection>
          {messages.map((msg, index) => (
            <SpeechBubble key={`speechBubble-${index}`} messageId={msg.id} handleSend={handleSend} />
          ))}
          {isThreadsLoading ? (
            <div className=" min-height-200">
              <Spinner text={intl.formatMessage({ id: 'chatPage.processIndicator.label' })} />
            </div>
          ) : null}
          <div ref={messagesEndRef} style={{ marginBottom: '-0.5rem' }} />
        </MessagesSection>
      </ContentWrapper>
      <ChatInput
        isLoading={isThreadsLoading}
        onSendMessage={(message) => handleSend(message, 'text', currentService)}
        onSendFile={(file) => handleSend(file, 'file')}
      />
    </ChatWindowWrapper>
  );
};

export default ChatWindow;

const ChatWindowWrapper = styled.div`
  padding: 2rem;
  background: var(--color-white);
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1rem);
  padding-right: 2rem;
  margin-bottom: 1rem;
  overflow-y: auto;
  overflow-x: auto;
  height: 100%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(var(--color-black), 0.4);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(var(--color-black), 0.1);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  scrollbar-width: thin;
  scrollbar-color: rgba(var(--color-black), 0.4) rgba(var(--color-black), 0.1);
`;

const MessagesSection = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const WelcomeSection = styled.div`
  text-align: left;
  width: 100%;
  padding: 0 0.5rem;
  flex-shrink: 0;
  margin-bottom: 1rem;
`;

const isFile = (content: string | File): content is File => {
  return (content as File).size !== undefined;
};

const isString = (content: string | File): content is string => {
  return (content as string) !== undefined;
};
