import useDarkMode from '@rio-cloud/rio-uikit/hooks/useDarkMode';
import FeedbackForm from './components/FeedbackForm/FeedbackForm';
import styled from 'styled-components';

const FeedbackPage = () => {
  const isDarkMode = useDarkMode();
  return (
    <div className="container padding-y-25">
      <GradientBackground isDarkMode={isDarkMode}>
        <ContentWrapper>
          <FeedbackForm />
          <FeedbackImage src="assets/images/feedback.svg" alt="Feedback" />
        </ContentWrapper>
      </GradientBackground>
    </div>
  );
};

export default FeedbackPage;

type GradientBackgroundProps = {
  isDarkMode: boolean;
};

const GradientBackground = styled.div<GradientBackgroundProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1680px;
  padding: 3rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  gap: 2rem;
`;

const FeedbackImage = styled.img`
  height: 400px;
  padding: 3rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;
