import { useIntl } from 'react-intl';

const getDatesToTranslations = () => {
  const intl = useIntl();

  const now = new Date();
  const lastMonth = now.getMonth() === 0 ? 11 : now.getMonth() - 1;
  const year = now.getMonth() === 0 ? now.getFullYear() - 1 : now.getFullYear();
  const lastMonthDate = new Date(year, lastMonth);
  const currentMonthDate = new Date(now.getFullYear(), now.getMonth());

  const startDate = new Date(year, lastMonth, 1);
  const endDate = new Date(year, lastMonth + 1, 0);
  const threeMonthsAgoMonth = now.getMonth() - 3 < 0 ? 12 + (now.getMonth() - 3) : now.getMonth() - 3;
  const threeMonthsAgoYear = now.getMonth() - 3 < 0 ? now.getFullYear() - 1 : now.getFullYear();
  const threeMonthsAgoDate = new Date(threeMonthsAgoYear, threeMonthsAgoMonth);

  const dates = {
    currentMonth: intl.formatDate(currentMonthDate, { year: 'numeric', month: 'long' }),
    threeMonthsAgo: intl.formatDate(threeMonthsAgoDate, { year: 'numeric', month: 'long' }),
    lastMonth: intl.formatDate(lastMonthDate, { month: 'long' }),
    startDate: intl.formatDate(startDate, { year: 'numeric', month: 'long', day: 'numeric' }),
    endDate: intl.formatDate(endDate, { year: 'numeric', month: 'long', day: 'numeric' }),
  };

  return dates;
};
export default getDatesToTranslations;
