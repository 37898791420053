import styled from 'styled-components';
import ServiceCard from '../ServiceCard/ServiceCard';
import { Service } from '../../../../store/service/serviceSlice';
import { useIntl } from 'react-intl';
import OnboardingTip, { OnboardingTipProps } from '@rio-cloud/rio-uikit/components/onboarding/OnboardingTip';
import OnboardingServiceCard from '../OnboardingServiceCard/OnboardingServiceCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  disableOnboardingMode,
  resetStep,
  selectIsOnboardingMode,
  Steps,
} from '../../../../store/onboarding/onboardingSlice';
import { selectIsSidebarOpen } from '../../../../store/sidebar/sidebarSlice';
import { removeThread, selectDemoThread } from '../../../../store/thread/threadSlice';
import getDatesToTranslations from '../../../../utils/getDatesToTranslations/getDatesToTranslations';

type ServiceSectionProps = {
  onClickQuestion: (value: string, service: Service, isOnboarding: boolean, step?: Steps) => void;
};

type Question = {
  question: string;
  iconclassName: string;
  service: Service;
};

const ServicesSection = ({ onClickQuestion }: ServiceSectionProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const isSidebarOpen = useSelector(selectIsSidebarOpen);
  const demoThread = useSelector(selectDemoThread);
  const dates = getDatesToTranslations();

  const exemplary_questions_performservice = [
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_one' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_two' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_three' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_four' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_five' }, dates),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_performservice_six' }, dates),
  ] as const;

  const exemplary_questions_mantraining = [
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_one' }),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_two' }),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_three' }),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_four' }),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_five' }),
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_mantraining_six' }),
  ] as const;

  const onboarding_questions_mantraining = [
    intl.formatMessage({ id: 'chatPage.servicesSection.exemplary_questions_onboarding_one' }),
  ] as const;

  const tipText = {
    title: intl.formatMessage({ id: 'chatPage.onboarding.title' }),
    subtitle: intl.formatMessage({ id: 'chatPage.onboarding.subtitle' }),
    buttonLeft: intl.formatMessage({ id: 'chatPage.onboarding.buttonLeft' }),
    buttonRight: intl.formatMessage({ id: 'chatPage.onboarding.buttonRight' }),
  } as const;

  const exemplary_questions: Question[] = [
    {
      question: exemplary_questions_mantraining[0],
      iconclassName: 'rioglyph-book',
      service: 'training',
    },
    {
      question: exemplary_questions_performservice[0],
      iconclassName: 'rioglyph-stats',
      service: 'perform',
    },
    {
      question: exemplary_questions_performservice[1],
      iconclassName: 'rioglyph-stats',
      service: 'perform',
    },
  ];

  const handleHideTooltip = () => dispatch(disableOnboardingMode());

  const handleNext = () => {
    if (demoThread) {
      dispatch(removeThread(demoThread.id));
      dispatch(resetStep());
    }
    onClickQuestion(onboarding_questions_mantraining[0], 'perform', isOnboardingMode, 'step-0');
  };

  return (
    <Container>
      {isOnboardingMode ? (
        <StyledOnboardingTip
          show={isOnboardingMode}
          isSidebarOpen={isSidebarOpen}
          placement="bottom-end"
          clickflow
          width={400}
          title={tipText.title}
          content={tipText.subtitle}
          showCloseIcon={false}
          previousButton={{
            text: tipText.buttonLeft,
            iconName: 'rioglyph-eye-close',
            onClick: handleHideTooltip,
          }}
          nextButton={{
            iconName: 'rioglyph-send',
            text: tipText.buttonRight,
            onClick: handleNext,
          }}
        >
          <OnboardingServiceCard
            description={onboarding_questions_mantraining[0]}
            iconClassName={'rioglyph-book'}
            onClickFunction={handleNext}
            service={'training'}
            key={`card-onboarding-1`}
          />
        </StyledOnboardingTip>
      ) : null}
      {exemplary_questions.map((question_item, id) => (
        <ServiceCard
          description={question_item.question}
          iconClassName={question_item.iconclassName}
          onClickFunction={() => onClickQuestion(question_item.question, question_item.service, false)}
          service={question_item.service as Service}
          key={`card-${question_item.question}-${id}`}
        />
      ))}
    </Container>
  );
};

export default ServicesSection;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;
  padding: 0.5rem;
  flex-shrink: 0;
  flex-wrap: wrap;
`;
interface StyledOnboardingTipProps extends OnboardingTipProps {
  isSidebarOpen: boolean;
}

const StyledOnboardingTip = styled(OnboardingTip)<StyledOnboardingTipProps>`
  position: absolute !important;
  left: ${(props) => (props.isSidebarOpen ? '420px' : '120px')} !important;
  top: 360px !important;
`;
