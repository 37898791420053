import { useNavigate } from 'react-router';
import PageNavigation from '../PageNavigation/PageNavigation';
import ToolCard from './components/ToolCard/ToolCard';
import styled from 'styled-components';
import { Fragment } from 'react/jsx-runtime';
import { FormattedMessage } from 'react-intl';
import { useEffect, useRef } from 'react';

const cards = [
  {
    title: 'getStartedPage.common.performanceAnalysis.label',
    imageName: 'perform-analysis-new',
    description: 'getStartedPage.common.performanceAnalysis.description',
  },
  {
    title: 'getStartedPage.common.manTraining.label',
    imageName: 'man-training-new',
    description: 'getStartedPage.common.manTraining.description',
  },
];

type ToolsProps = {
  handlePreviousSection: () => void;
};
const Tools = ({ handlePreviousSection }: ToolsProps) => {
  const navigate = useNavigate();
  const handleGoToChat = () => navigate('/chat');

  const pageTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Fragment>
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <div className="panel panel-default panel-body panel-blank position-relative padding-x-25 margin-10">
        <p>
          <FormattedMessage id="getStartedPage.tools.introduction" />
        </p>
      </div>
      <GridContainer>
        {cards.map((card, index) => (
          <ToolCard key={index} title={card.title} imageName={card.imageName} description={card.description} />
        ))}
      </GridContainer>
      <div className="panel panel-default panel-body panel-blank position-relative margin-top-20 padding-x-25 width-100pct">
        <PageNavigation
          onNext={handleGoToChat}
          onPrevious={handlePreviousSection}
          previousLabel={'getStartedPage.howToWritePrompts.label'}
          nextLabel={'getStartedPage.common.startChat.label'}
        />
      </div>
    </Fragment>
  );
};

export default Tools;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  padding: 0;
  margin-top: 25px;
`;
