type FAQs = {
    id: string;
    translation: string;
    icon: string;
};

export const getMockFAQs = (): FAQs[] => (
    [
        {
            id: '1',
            translation: 'chatPage.servicesSection.exemplary_questions_performservice_one',
            icon: 'stats',
        },
        {
            id: '2',
            translation: 'chatPage.servicesSection.exemplary_questions_performservice_two',
            icon: 'stats',
        },
        {
            id: '3',
            translation: 'chatPage.servicesSection.exemplary_questions_performservice_three',
            icon: 'stats',
        },
        {
            id: '4',
            translation: 'chatPage.servicesSection.exemplary_questions_performservice_four',
            icon: 'stats',
        },
        {
            id: '5',
            translation: 'chatPage.servicesSection.exemplary_questions_performservice_five',
            icon: 'stats',
        },
        {
            id: '6',
            translation: 'chatPage.servicesSection.exemplary_questions_performservice_six',
            icon: 'stats',
        },
        {
            id: '7',
            translation: 'chatPage.servicesSection.exemplary_questions_mantraining_one',
            icon: 'book',
        },
        {
            id: '8',
            translation: 'chatPage.servicesSection.exemplary_questions_mantraining_two',
            icon: 'book',
        },
        {
            id: '9',
            translation: 'chatPage.servicesSection.exemplary_questions_mantraining_three',
            icon: 'book',
        },
        {
            id: '10',
            translation: 'chatPage.servicesSection.exemplary_questions_mantraining_four',
            icon: 'book',
        },
        {
            id: '11',
            translation: 'chatPage.servicesSection.exemplary_questions_mantraining_five',
            icon: 'book',
        },
        {
            id: '12',
            translation: 'chatPage.servicesSection.exemplary_questions_mantraining_six',
            icon: 'book',
        }
    ]
);
