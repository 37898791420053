import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Message } from '../../store/chat/chat.slice';
import { prepareHeaders } from '../../utils/prepareHeaders';
import { Response } from '../../utils/types';

type RouteRequest = {
  service: string;
  question: string;
  collection_name: string;
  language: string;
  threadId: string;
  userId: string;
};

type UploadFileRequest = {
  file: File;
  threadId: string;
  file_name: string;
  path: string;
};

export const chatApi = createApi({
  reducerPath: 'chatApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${import.meta.env.VITE_BASE_BACKEND_URL}`, prepareHeaders }),
  endpoints: (builder) => ({
    sendMessage: builder.mutation<Response<Message>, Partial<RouteRequest>>({
      query: (message) => {
        const mappedMessage = {
          ...message,
          user_id: message.userId,
        };
        delete mappedMessage.userId;
        return {
          url: '/v1/route/',
          method: 'POST',
          body: mappedMessage,
        };
      },
      refetchOnError: true,
      transformResponse: (response: Response<Message>, meta, arg) => {
        if (arg.threadId) {
          return {
            ...response,
            data: {
              ...response.data,
              threadId: arg.threadId,
            },
          };
        }
        return response;
      },
    }),
    sendMessageToAskYourDocs: builder.mutation<Response<Message>, Partial<RouteRequest>>({
      query: (message) => ({
        url: '/ask-your-docs/',
        method: 'POST',
        body: message,
      }),
      transformResponse: (response: Response<Message>, meta, arg) => {
        if (arg.threadId) {
          return {
            ...response,
            data: {
              ...response.data,
              threadId: arg.threadId,
            },
          };
        }
        return response;
      },
    }),
    uploadFile: builder.mutation<Response<Message>, UploadFileRequest>({
      query: ({ file, threadId }) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('threadId', threadId);

        return {
          url: '/ask-your-docs/',
          method: 'PUT',
          body: formData,
        };
      },
      transformResponse: (response: Response<Message>, meta, arg) => {
        if (arg.threadId) {
          return {
            ...response,
            data: {
              ...response.data,
              threadId: arg.threadId,
            },
          };
        }
        return response;
      },
    }),
  }),
});

export const { useSendMessageMutation, useSendMessageToAskYourDocsMutation, useUploadFileMutation } = chatApi;
