import OnboardingTip from '@rio-cloud/rio-uikit/components/onboarding/OnboardingTip';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import { MessageActionBar } from '../MessageActionBar/MessageActionBar';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsLoading, selectMessageById } from '../../../../store/chat/chat.slice';
import { FormattedMessage, useIntl } from 'react-intl';
import { selectCurrentService, Service } from '../../../../store/service/serviceSlice';
import {
  disableOnboardingMode,
  resetStep,
  selectIsOnboardingMode,
  selectOnboardingStep,
  Steps,
  steps,
} from '../../../../store/onboarding/onboardingSlice';
import { mockOnboardingMessageDE } from '../../../../../mocks/mockOnboardingMessageDE';
import { mockOnboardingMessageEN } from '../../../../../mocks/mock.onboardingMessageEN';
import { getLocale } from '../../../../configuration/lang/langSlice';
import { markdownRenderers } from '../../../../utils/markdownRenderers/markdownRenderers';
import { selectActiveThreadId, selectDemoThread, setActiveThread } from '../../../../store/thread/threadSlice';
import { useEffect, useRef, useState } from 'react';
import OverlayTrigger from '@rio-cloud/rio-uikit/components/overlay/OverlayTrigger';
import Tooltip from '@rio-cloud/rio-uikit/components/tooltip/Tooltip';

export type SpeechBubbleProps = {
  messageId: number;
  handleSend: (
    content: string,
    type: 'text' | 'file',
    service: Service,
    isOnboarding: boolean,
    nextStepIndex?: Steps,
  ) => void;
};

type StepToService = {
  [key in Steps]: Service;
};

const stepToService: StepToService = {
  'step-0': 'perform',
  'step-1': 'perform',
  'step-2': 'training',
  'step-3': 'perform',
  'step-4': 'perform',
};

const texts = {
  titles: {
    wellDone: 'chatPage.onboarding.wellDone',
    onboardingComplete: 'chatPage.onboarding.onboardingCompleteTitle',
  },
  contents: {
    tryNextQuestion: 'chatPage.onboarding.tryNextQuestion',
    onboardingComplete: 'chatPage.onboarding.onboardingCompleteContent',
  },
  buttons: {
    skip: 'chatPage.onboarding.buttonSkip',
    tryNextQuestion: 'chatPage.onboarding.buttonTryNextQuestion',
    okThanks: 'chatPage.onboarding.buttonOkThanks',
    tryAgain: 'chatPage.onboarding.tryAgain',
  },
  icons: {
    skipIcon: 'rioglyph-eye-close',
    sendIcon: 'rioglyph-send',
    repeat: 'rioglyph-repeat',
  },
  editTooltip: 'chatPage.SpeechBubble.edit.tooltip',
  sendButton: 'chatPage.SpeechBubble.edit.button.send',
  cancelButton: 'chatPage.SpeechBubble.edit.button.cancel',
  roleLabels: {
    ai: 'chatPage.SpeechBubble.role.ai.label',
    you: 'chatPage.SpeechBubble.role.you.label',
  },
};

const SpeechBubble = ({ messageId, handleSend }: SpeechBubbleProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { content, role, type, isOnboardingQuestion } = useSelector(selectMessageById(messageId));
  const onboardingStep = useSelector(selectOnboardingStep);
  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const currentLanguage = useSelector(getLocale);
  const currentService = useSelector(selectCurrentService);
  const activeThread = useSelector(selectActiveThreadId);
  const demoThreadId = useSelector(selectDemoThread)?.id;
  const isLoading = useSelector(selectIsLoading);
  const demoThread = useSelector(selectDemoThread);

  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  let fileContent;

  const questions = currentLanguage === 'de-DE' ? mockOnboardingMessageDE : mockOnboardingMessageEN;
  const currentStepIndex = onboardingStep !== undefined ? steps.indexOf(onboardingStep) : -1;
  const nextStepIndex = currentStepIndex + 1 < steps.length ? currentStepIndex + 1 : 0;
  const nextQuestion = nextStepIndex !== undefined ? questions[steps[nextStepIndex]]?.question : '';

  const handleNextStep = () => {
    if (onboardingStep === 'step-4') {
      dispatch(resetStep());
      if (demoThread) dispatch(setActiveThread(demoThread?.id));
      handleSend(
        intl.formatMessage({ id: texts.buttons.tryNextQuestion }),
        'text',
        stepToService[onboardingStep],
        true,
        'step-1',
      );
    } else handleSend(nextQuestion, 'text', stepToService[onboardingStep ?? 'step-0'], true, steps[nextStepIndex]);
  };

  const handleOnHide = () => dispatch(disableOnboardingMode());

  const handleEditClick = () => setIsEditing(true);

  const handleEditSubmit = () => {
    setIsEditing(false);
    handleSend(editedContent, 'text', currentService, false);
  };

  const handleEditCancel = () => {
    setIsEditing(false);
    setEditedContent(content);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isEditing) {
      adjustTextareaHeight();
    }
  }, [isEditing, editedContent]);

  if (type === 'file') {
    const fileMessage = JSON.parse(content);
    fileContent = (
      <FileMessage>
        <div className="padding-5 text-size-60pct">
          <span className="rioglyph rioglyph-files text-size-300pct" />
        </div>
        <FileDetails>
          <FileName>{fileMessage.fileName}</FileName>
          <FileSize>{fileMessage.fileSize}</FileSize>
        </FileDetails>
      </FileMessage>
    );
  } else {
    fileContent = (
      <TextMessage>
        {isEditing ? (
          <div className="form-group width-100pct display-flex flex-column">
            <textarea
              ref={textareaRef}
              className="form-control"
              id="textArea"
              value={editedContent}
              onBlur={handleEditSubmit}
              onChange={(e) => setEditedContent(e.target.value)}
            />
            <div className="display-flex gap-10 justify-content-end margin-top-10">
              <button className="btn btn-outline btn-secondary" onClick={handleEditCancel}>
                <FormattedMessage id={texts.cancelButton} />
              </button>
              <button className="btn btn-primary" onClick={handleEditSubmit}>
                <FormattedMessage id={texts.sendButton} />
              </button>
            </div>
          </div>
        ) : (
          <StyledOnboardingTip
            show={
              !isLoading &&
              isOnboardingQuestion &&
              isOnboardingMode &&
              activeThread === demoThreadId &&
              onboardingStep !== undefined
            }
            placement="auto-start"
            preventOverflow
            width={400}
            title={
              onboardingStep !== 'step-4'
                ? intl.formatMessage({ id: texts.titles.wellDone })
                : intl.formatMessage({ id: texts.titles.onboardingComplete })
            }
            content={
              onboardingStep !== 'step-4'
                ? intl.formatMessage({ id: texts.contents.tryNextQuestion })
                : intl.formatMessage({ id: texts.contents.onboardingComplete })
            }
            showCloseIcon={false}
            clickflow
            previousButton={{
              text:
                onboardingStep !== 'step-4'
                  ? intl.formatMessage({ id: texts.buttons.skip })
                  : intl.formatMessage({ id: texts.buttons.okThanks }),
              iconName: onboardingStep !== 'step-4' ? texts.icons.skipIcon : '',
              onClick: handleOnHide,
            }}
            nextButton={{
              iconName: onboardingStep !== 'step-4' ? texts.icons.sendIcon : texts.icons.repeat,
              text:
                onboardingStep !== 'step-4'
                  ? intl.formatMessage({ id: texts.buttons.tryNextQuestion })
                  : intl.formatMessage({ id: texts.buttons.tryAgain }),
              onClick: handleNextStep,
            }}
          >
            <ReactMarkdown components={markdownRenderers(content)} remarkPlugins={[remarkGfm]}>
              {content}
            </ReactMarkdown>
          </StyledOnboardingTip>
        )}
      </TextMessage>
    );
  }

  return (
    <BubbleWrapper role={role}>
      {role === 'assistant' && (
        <div className="text-color-darkest text-size-14 text-medium margin-bottom-5 gap-5 align-items-center">
          <span className="rioglyph rioglyph-robot text-size-16" aria-hidden="true" />
          <span>FleetAssistant</span>
        </div>
      )}
      <HoverArea>
        {role === 'human' && !isEditing && (
          <OverlayTrigger
            key={'edit-tooltip'}
            placement={'auto-start'}
            overlay={
              <Tooltip id="tooltip-edit" allowOnTouch>
                <FormattedMessage id={texts.editTooltip} />
              </Tooltip>
            }
          >
            <EditIcon onClick={handleEditClick} data-testid="icon-edit" className="rioglyph rioglyph-pencil" />
          </OverlayTrigger>
        )}
        <Bubble role={role} data-testid={`speech_bubble-${role}`}>
          {fileContent}
          {role === 'assistant' ? <MessageActionBar messageId={messageId} handleSend={handleSend} /> : null}
        </Bubble>
      </HoverArea>
    </BubbleWrapper>
  );
};

export default SpeechBubble;

const EditIcon = styled.span`
  border-radius: 50%;
  padding: 0.7rem;
  background: var(--gray-lightest);
  margin-left: 0.5rem;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  top: 15px;
  left: -40px;
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;

  &:hover {
    opacity: 1;
    visibility: visible;
  }
`;

const HoverArea = styled.div`
  position: relative;
  display: flex;
  justify-content: ${({ role }) => (role === 'human' ? 'flex-end' : 'flex-start')};

  &:hover ${EditIcon} {
    opacity: 1;
    visibility: visible;
  }
`;

const BubbleWrapper = styled.div<{ role: 'human' | 'assistant' }>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ role }) => (role === 'human' ? 'flex-end' : 'flex-start')} !important;
  align-items: ${({ role }) => (role === 'human' ? 'end' : 'start')} !important;
  margin: 0.8rem 0;
  padding-left: ${({ role }) => (role === 'human' ? '4rem' : 0)} !important;
  width: 100%;
  position: relative;
`;

const Bubble = styled.div<{ role: 'human' | 'assistant' }>`
  background: ${({ role }) => (role === 'human' ? 'var(--gray-lightest)' : 'var(--color-highlight-lightest)')};
  color: var(--gray-darkest);
  padding: 1rem 2rem;
  margin-top: ${({ role }) => (role === 'human' ? '1rem' : 0)};
  word-wrap: break-word;
  border-radius: 6px;
  position: relative;
  min-width: 400px;
`;

const TextMessage = styled.div`
  word-wrap: break-word;
`;

const FileMessage = styled.div`
  display: flex;
  align-items: center;
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const FileName = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const FileSize = styled.div`
  color: gray;
`;

const StyledOnboardingTip = styled(OnboardingTip)`
  position: absolute !important;
  left: 65% !important;
  top: 30% !important;

  & > div.tooltip-arrow {
    display: none;
  }

  @media (min-width: 768px) {
    left: 55% !important;
  }
`;
