import { FormattedMessage } from 'react-intl';
import { ChatThread } from '../../../../store/thread/threadSlice';
import ThreadListItem from '../ThreadListItem/ThreadListItem';

type ChatThreadCategoryProps = {
  title: string;
  threads: ChatThread[];
  isFavorite?: boolean;
};

const ChatThreadCategory = ({ title, threads, isFavorite = false }: ChatThreadCategoryProps) => (
  <div>
    <p className="text-bold padding-x-10">
      <FormattedMessage id={title} />
    </p>
    <ul className="overflow-auto list-group padding-0">
      {threads.map((thread) =>
        thread.firstMessage ? (
          <ThreadListItem
            key={`not-favorite-${thread.id}`}
            threadId={thread.id}
            firstMessage={thread.firstMessage}
            isFavorite={isFavorite}
          />
        ) : null,
      )}
    </ul>
  </div>
);

export default ChatThreadCategory;
