import { Fragment, useEffect, useState } from 'react';
import ApplicationLayout from '@rio-cloud/rio-uikit/components/applicationLayout/ApplicationLayout';
import HowAssistantWorks from './components/HowAssistantWorks/HowAssistantWorks';
import HowWritePrompts from './components/HowWritePrompts/HowWritePrompts';
import Navbar from './components/Navbar/Navbar';
import Tools from './components/Tools/Tools';
import HeroSection from './components/HeroSection/HeroSection';
import WhatIsFleetAssistant from './components/WhatIsFleetAssistant/WhatIsFleetAssistant';
import FeaturesSection from './components/FeatureSection/FeaturesSection';
import HowItWorksSection from './components/HowItWorksSection/HowItWorksSection';
import { FormattedMessage } from 'react-intl';

const items = ['item1', 'item2', 'item3', 'item4', 'item5'] as const;

export type NavbarItem = (typeof items)[number];

type ItemProps = {
  translation: string;
  component: JSX.Element;
};

export type ItemToContent = {
  [key in NavbarItem]: ItemProps;
};

type GetStartedPageProps = {
  activePage: NavbarItem;
};

const GetStartedPage = ({ activePage }: GetStartedPageProps) => {
  const [activeItem, setActiveItem] = useState<NavbarItem>(activePage);

  const handleGoToNextSection = (nextItem: NavbarItem) => {
    setActiveItem(nextItem);
  };

  const navTitle = 'common.gettingStarted.label';
  const itemToContent: ItemToContent = {
    item1: {
      translation: 'getStartedPage.welcome.label',
      component: (
        <Fragment>
          <HeroSection />
          <FeaturesSection />
          <HowItWorksSection handleReadMore={() => handleGoToNextSection('item2')} />
        </Fragment>
      ),
    },
    item2: {
      translation: 'getStartedPage.whatIsFleetAssistant.label',
      component: (
        <WhatIsFleetAssistant
          handleNextSection={() => handleGoToNextSection('item3')}
          handlePreviousSection={() => handleGoToNextSection('item1')}
        />
      ),
    },
    item3: {
      translation: 'getStartedPage.howVirtualAssistantWorks.label',
      component: (
        <HowAssistantWorks
          handleNextSection={() => handleGoToNextSection('item4')}
          handlePreviousSection={() => handleGoToNextSection('item2')}
        />
      ),
    },
    item4: {
      translation: 'getStartedPage.howToWritePrompts.label',
      component: (
        <HowWritePrompts
          handleNextSection={() => handleGoToNextSection('item5')}
          handlePreviousSection={() => handleGoToNextSection('item3')}
        />
      ),
    },
    item5: {
      translation: 'getStartedPage.tools.label',
      component: <Tools handlePreviousSection={() => handleGoToNextSection('item4')} />,
    },
  } as const;

  const itemsArray = [];
  for (const link in itemToContent) {
    if (Object.prototype.hasOwnProperty.call(items, link)) {
      itemToContent[link as NavbarItem] !== undefined &&
        itemsArray.push(
          <li key={link} className="nav-item">
            <span>{itemToContent[link as NavbarItem].translation}</span>
          </li>,
        );
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeItem]);

  return (
    <div className="container fluid-extra-large padding-top-25">
      <ApplicationLayout.Sidebar>
        <Navbar title={navTitle} items={itemToContent} activeItem={activeItem} setActiveItem={setActiveItem} />
      </ApplicationLayout.Sidebar>
      <ApplicationLayout.Body className={`'${activeItem === 'item1' ? 'padding-0' : ''}`}>
        {activeItem !== 'item1' ? (
          <h2 className="h2 margin-bottom-15 margin-top-0">
            <FormattedMessage id={itemToContent[activeItem].translation} />
          </h2>
        ) : null}
        {itemToContent[activeItem].component}
      </ApplicationLayout.Body>
    </div>
  );
};

export default GetStartedPage;
