const routeFeature = ['homepage', 'getStarted', 'feedback', 'chat'] as const;
const routesUrl = ['/', '/get-started', '/feedback', '/chat'] as const;

type RouteFeature = (typeof routeFeature)[number];
export type RoutesUrl = (typeof routesUrl)[number];

type Getters<T extends string, K> = {
  [k in T]: K;
};

type RoutesData = Getters<RouteFeature, RoutesUrl>;

const routes: RoutesData = {
  homepage: '/',
  getStarted: '/get-started',
  chat: '/chat',
  feedback: '/feedback',
} as const;

export default routes;
