import { Fragment } from 'react/jsx-runtime';
import TableActionBar from '../../pages/ChatPage/components/TableActionBar/TableActionBar';
import LimitedList from '../../pages/ChatPage/components/LimitedList/LimitedList';
import styled from 'styled-components';

export const markdownRenderers = (message) => ({
  pre: ({ children }) => <div>{children}</div>,
  code: ({ children }) => <div>{children}</div>,
  a: ({ href, children }) => (
    <button
      type="button"
      className="btn btn-outline btn-primary border-none padding-20 hover-text-color-primary hover-bg-lighter margin-left-10"
    >
      <span className="rioglyph rioglyph-files" aria-hidden="true" />
      <span className="text-capitalize">
        <a href={href} target="_blank" rel="noopener noreferrer" className="text-color-primary">
          {children}
        </a>
      </span>
    </button>
  ),
  p: ({ children }) => <div className="padding-y-5">{children}</div>,
  ul: ({ children }) => <LimitedList>{children}</LimitedList>,
  ol: ({ children }) => <LimitedList>{children}</LimitedList>,
  table: ({ children }) => (
    <Fragment>
      <TableContainer className="table-responsive">
        <StyledTable className="table table-head-filled table-bordered">{children}</StyledTable>
      </TableContainer>
      <TableActionBar message={message} />
    </Fragment>
  ),
  th: ({ children }) => <th>{children}</th>,
  td: ({ children }) => <StyledTd>{children}</StyledTd>,
  tr: ({ children }) => <tr>{children}</tr>,
});

const TableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  word-break: break-word;
`;

const StyledTd = styled.td`
  word-break: break-word;
`;
