import { FormattedMessage, useIntl } from 'react-intl';
import PageNavigation from '../PageNavigation/PageNavigation';
import { useEffect, useRef } from 'react';

const howAssistantWorksText = {
  intro: 'getStartedPage.howAssistantWorksPrompt.introduction',
  steps: [
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_one.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_one.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_two.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_two.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_three.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_three.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_four.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_four.description',
    },
    {
      title: 'getStartedPage.howAssistantWorksPrompt.step_five.title',
      description: 'getStartedPage.howAssistantWorksPrompt.step_five.description',
    },
  ],
  visualizationSection: {
    title: 'getStartedPage.howAssistantWorksPrompt.diagram.title',
    description: 'getStartedPage.howAssistantWorksPrompt.diagram.description',
    imgAlt: 'getStartedPage.howAssistantWorksPrompt.diagram.alt',
  },
  pageNavigation: {
    previousLabel: 'getStartedPage.whatIsFleetAssistant.label',
    nextLabel: 'getStartedPage.howToWritePrompts.label',
  },
};

type HowAssistantWorksPrompt = {
  handleNextSection: () => void;
  handlePreviousSection: () => void;
};

const HowAssistantWorks = ({ handleNextSection, handlePreviousSection }: HowAssistantWorksPrompt) => {
  const intl = useIntl();

  const pageTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageTopRef.current) {
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className="panel panel-default panel-body panel-blank padding-x-25 margin-top-25">
      <div ref={pageTopRef} style={{ position: 'absolute', top: '0' }} />
      <p className="line-height-150rel">
        <FormattedMessage id={howAssistantWorksText.intro} />
      </p>

      <div className="timeline margin-top-50">
        {howAssistantWorksText.steps.map((step, index) => (
          <div key={index} className="timeline-element margin-bottom-0">
            <div className="timeline-date timeline-date-small timeline-date-thin margin-right-10 margin-top-5">
              <div className="width-10 aspect-ratio-1 rounded-circle border border-color-info border-width-2 bg-white margin-left-1" />
            </div>
            <div className="timeline-content margin-bottom-20 text-color-darker">
              <strong>
                <FormattedMessage id={step.title} />
              </strong>
              <p>
                <FormattedMessage id={step.description} />
              </p>
            </div>
          </div>
        ))}
      </div>

      <section className="margin-top-40 margin-bottom-20">
        <h3 className="text-size-h3 text-bold margin-bottom-10">
          <FormattedMessage id={howAssistantWorksText.visualizationSection.title} />
        </h3>
        <p className="line-height-150rel">
          <FormattedMessage id={howAssistantWorksText.visualizationSection.description} />
        </p>
        <div className="text-center margin-bottom-20">
          <img
            className="max-width-90pct bg-darkest padding-y-25 padding-x-50"
            src="assets/images/diagram.svg"
            alt={intl.formatMessage({ id: howAssistantWorksText.visualizationSection.imgAlt })}
          />
        </div>
      </section>
      <PageNavigation
        onNext={handleNextSection}
        onPrevious={handlePreviousSection}
        previousLabel={howAssistantWorksText.pageNavigation.previousLabel}
        nextLabel={howAssistantWorksText.pageNavigation.nextLabel}
      />
    </div>
  );
};

export default HowAssistantWorks;
