import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOnboardingMode, toggleOnboardingMode } from '../../../../../store/onboarding/onboardingSlice';
import { useIntl } from 'react-intl';
import ReleaseNotes from '../../../ReleaseNotes/ReleaseNotes';
import { useState } from 'react';
import packageInfo from '../../../../../../package.json';

const serviceInfoTitle = 'Fleet Assistant';
const serviceInfoVersion = packageInfo.version;

const texts = {
  activateOnboardingMode: 'common.header.onboardingMode.activate.label',
  stopOnboardingMode: 'common.header.onboardingMode.stop.label',
};

const ServiceInfo = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const isOnboardingMode = useSelector(selectIsOnboardingMode);
  const [isOpen, setOpen] = useState(false);

  const handleShowReleaseNotes = () => {
    setOpen(true);
    document.querySelector('.modal')?.classList.toggle('show');
  };
  const handleActivateOnboardingMode = () => dispatch(toggleOnboardingMode());
  const handleCloseReleaseNotes = () => {
    setOpen(false);
    document.querySelector('.modal')?.classList.toggle('show');
  };

  const title = (
    <div>
      <button type="button" className="btn btn-secondary btn-action " onClick={handleActivateOnboardingMode}>
        <div className="display-flex justify-content-start gap-10 align-items-center">
          <span
            className={`rioglyph rioglyph ${isOnboardingMode ? 'rioglyph-pause' : 'rioglyph-play'}`}
            aria-hidden="true"
          />
          <span>
            {intl.formatMessage({ id: isOnboardingMode ? texts.stopOnboardingMode : texts.activateOnboardingMode })}
          </span>
        </div>
      </button>
    </div>
  );

  return (
    <ActionBarItem id="serviceInfo" className="myItem">
      <ReleaseNotes isOpen={isOpen} onClose={handleCloseReleaseNotes} />
      <ActionBarItem.Icon>
        <span className="icon rioglyph rioglyph-info-sign" />
      </ActionBarItem.Icon>
      <ActionBarItem.Popover title={title} className="margin-left-10">
        <ActionBarItem.List>
          <div className="margin-bottom-10">
            <span>{serviceInfoTitle}</span>
            <span className="text-color-gray margin-left-10">{serviceInfoVersion}</span>
          </div>
          <ActionBarItem.ListItem icon="rioglyph-hand-right" onClick={handleShowReleaseNotes}>
            What&apos;s new?
          </ActionBarItem.ListItem>
        </ActionBarItem.List>
      </ActionBarItem.Popover>
    </ActionBarItem>
  );
};

export default ServiceInfo;
