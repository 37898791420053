import { Route, Routes } from 'react-router-dom';
import AppLayout from './AppLayout';
import ChatPage from '../pages/ChatPage/ChatPage';
import FeedbackPage from '../pages/FeedbackPage/FeedbackPage';
import GetStartedPage from '../pages/GetStartedPage/GetStartedPage';
import routes from '../routes/routes';
import ProtectedRoute from '../routes/ProtectedRoute/ProtectedRoute';

const App = () => (
  <Routes>
    <Route path="/" element={<AppLayout />}>
      <Route
        path={routes.homepage}
        element={
          <ProtectedRoute>
            <GetStartedPage activePage="item1" />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.getStarted}
        element={
          <ProtectedRoute>
            <GetStartedPage activePage="item1" />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.chat}
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.feedback}
        element={
          <ProtectedRoute>
            <FeedbackPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <GetStartedPage activePage="item1" />
          </ProtectedRoute>
        }
      />
    </Route>
  </Routes>
);

export default App;
