import { useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';
import { isUserSessionExpired } from '../configuration/login/loginSlice';
import { useAppDispatch, useAppSelector } from '../configuration/setup/hooks';
import { getDisplayMessages, getLocale } from '../configuration/lang/langSlice';
import RouteUpdater from '../routes/RouteUpdater';
import { getSessionExpiredAcknowledged, hideSessionExpiredDialog } from '../store/app/appSlice';
import { AppContext } from './AppContext';
import Header from '../common/components/Header/Header';
import './App.css';

const AppLayout = () => {
  const dispatch = useAppDispatch();

  const sidebarRef = useRef<HTMLDivElement>(null);

  const userLocale = useAppSelector(getLocale);
  const displayMessages = useAppSelector(getDisplayMessages);
  const isSessionExpired = useAppSelector(isUserSessionExpired);
  const sessionExpiredAcknowledged = useAppSelector(getSessionExpiredAcknowledged);

  if (!(displayMessages && userLocale)) {
    return null;
  }

  const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog());
  const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

  return (
    <AppContext.Provider value={{ sidebarRef }}>
      <ApplicationLayout data-testid={'app-layout'}>
        <ApplicationLayout.Header>
          <Header />
        </ApplicationLayout.Header>
        <NotificationsContainer />
        <SessionExpiredDialog locale={userLocale} onClose={handleSessionExpiredDialogClose} show={showSessionExpired} />
        <Outlet />
        <RouteUpdater />
      </ApplicationLayout>
    </AppContext.Provider>
  );
};

export default AppLayout;
