import Button from '@rio-cloud/rio-uikit/components/button/Button';
import Sidebar from '@rio-cloud/rio-uikit/components/sidebars/Sidebar';
import styled from 'styled-components';
import { ItemToContent, NavbarItem } from '../../GetStartedPage';
import { FormattedMessage } from 'react-intl';

type NavbarProps = {
  title: string;
  items: ItemToContent;
  activeItem: NavbarItem;
  setActiveItem: (item: NavbarItem) => void;
};

const Navbar = ({ title, items, activeItem, setActiveItem }: NavbarProps) => {
  const links = [];
  for (const item in items) {
    if (Object.prototype.hasOwnProperty.call(items, item)) {
      links.push(
        <StyledListItem
          key={item}
          isActive={activeItem === item}
          className={` ${activeItem === item ? ' list-group-item-primary' : ''}`}
        >
          <NavbarLinkItem
            className="btn btn-info"
            variant={Button.VARIANT_LINK}
            onClick={() => setActiveItem(item as NavbarItem)}
            isActive={activeItem === item}
          >
            <FormattedMessage id={items[item as NavbarItem].translation} />
          </NavbarLinkItem>
        </StyledListItem>,
      );
    }
  }

  return (
    <StyledSidebar
      titleClassName="padding-left-10"
      closed={false}
      onClose={() => null}
      width={260}
      minWidth={240}
      maxWidth={400}
      position={Sidebar.LEFT}
      disableClose
    >
      <ul className="nav list-group padding-10">
        <p className="text-bold text-size-16 padding-left-15">
          <FormattedMessage id={title} />
        </p>
        {links}
      </ul>
    </StyledSidebar>
  );
};

export default Navbar;

type NavbarLinkItemProps = {
  isActive: boolean;
};

const StyledListItem = styled('li')<NavbarLinkItemProps>`
  border: none;
  border-left: ${(props) => (props.isActive ? '3px' : '0')} solid
    ${(props) => (props.isActive ? 'var(--brand-primary)' : 'var(--gray-lightest)')} !important;
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  margin-left: 1.5rem;
`;

const NavbarLinkItem = styled(Button)<NavbarLinkItemProps>`
  justify-content: start;
  color: ${(props) => (props.isActive ? 'var(--brand-primary)' : 'var(--color-black)')} !important;
  font-weight: ${(props) => (props.isActive ? '600' : 'normal')};
  border-radius: 0;
  padding: 1rem;
`;

const StyledSidebar = styled(Sidebar)`
  & > div.SidebarContent > .SidebarHeader {
    display: none;
  }
`;
